import configTest from './config/config-test.js';
import configs from './config/config.js';
import psl from "psl";

/*
 * Config
 */

export function config(key) {
    const properties = Array.isArray(key) ? key : key.split('.');

    return properties.reduce((prev, curr) => prev && prev[curr], (window.location.href.includes('fitlap.ee') ? configs : configTest));
}

/*
 * Helpers
 */

export function redirect(url) {
    window.location.href = url;
}

export function removeCookie(name) {
    setCookie(name, '', 0);
}

export function setCookie(name, value, days, hours) {
    let expires = "";

    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        date.setHours(0, 0, 0, 0);
        expires = "; expires=" + date.toGMTString();
    }

    if (hours) {
        let now = new Date();

        now.setTime(now.getTime() + hours * 3600 * 1000);

        expires = '; expires=' + now.toUTCString();
    }

    // get full domain name without subdomain
    const parsed = psl.parse(window.location.hostname);
    let domain = parsed.domain;

    // Dont set . for dev env
    if (!config('dev')) {
        domain = '.' + domain
    } else {
        domain = window.location.hostname;
    }

    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/; domain=" + domain;
}

export function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }

    return "";
}