import React, {useEffect, useState} from "react";
import {Layout, Breadcrumb, Input, Button, Tabs, Table, Tag, Space, Popconfirm, message, Row} from 'antd';
import {Link, useNavigate} from "react-router-dom"
import Api from "../api";
import {CheckOutlined, DeleteOutlined, HeartFilled, MessageOutlined, EyeInvisibleOutlined} from '@ant-design/icons';
const {TabPane} = Tabs;
const {Search} = Input;
const {Content} = Layout;

const Recipes = () => {
    const [recipes, setRecipes] = useState([]);
    const [meta, setMeta] = useState(false);
    const [active, setActive] = useState('fitlap');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [search, setSearch] = useState("");
    const navigate = useNavigate();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 30,
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: img => <img className="recipe-img" src={img}/>,
            width: 100,
        },
        {
            title: 'Name',
            dataIndex: 'original_name',
            key: 'name',
            render: (name, record) => {
                return (
                    <Link to={"/recipe/" + record.id}>{name}</Link>
                )
            },
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: value => <span>-</span>,
            width: 100,
        },
        {
            title: <><HeartFilled style={{color: "red"}} /></>,
            dataIndex: 'likes',
            key: 'likes',
            render: likes => <span>0</span>,
            width: 100,
        },
        {
            title: <><MessageOutlined style={{color: "grey"}}/></>,
            dataIndex: 'comments_count',
            key: 'comments_count',
            render: comments => <span>{comments}</span>,
            width: 120,
        },
        {
            title: 'Time',
            dataIndex: 'preparation_time',
            key: 'preparation_time',
            render: text => <span>{text}min</span>,
            width: 30,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: text => <span><Tag color={text === 'active' ? "green" : "red"}>{text}</Tag></span>,
        },
    ];

    const userPublicColumns = [
        {
            title: "Action",
            key: "action",
            width: 90,
            render: (text, record) =>
                <Space size="large">
                    <Popconfirm
                        title={`Hide recipe: ${record.original_name}?`}
                        onConfirm={() => hideUserPublicRecipe(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a key="delete-comment" className="delete-comment"><EyeInvisibleOutlined /> hide</a>
                    </Popconfirm>
                </Space>,
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 30,
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: img => <img className="recipe-img" src={img}/>,
            width: 100,
        },
        {
            title: 'Name',
            dataIndex: 'original_name',
            key: 'name',
            render: (name, record) => {
                return (
                    <Link to={"/recipe/" + record.id}>{name}</Link>
                )
            },
        },
    ];

    useEffect(() => {
        loadRecipes()
    }, [active])

    const hideUserPublicRecipe = (recipe) => {
        Api.put('/admin/recipes/hide/' + recipe).then(res => {
            message.success('Recipe hidden');
            loadRecipes();
        })
    }

    const loadRecipes = (nextPage, keywords) => {
        let params = `&per_page=${pageSize}`

        if (keywords || search) {
            if(keywords && keywords !== search) {
                params += `&keywords=${keywords}`;
            } else {
                params += `&keywords=${!search ? keywords : search}`;
            }

        }

        if (nextPage) {
            params += `&page=${nextPage}`;
        } else {
            params += '&page=1';
        }

        Api.get('/admin/recipes?type=' + active + params).then(response => {
            setPage(response.data.current_page);
            setMeta(response.data.meta);
            setRecipes(response.data.data);
        })
    };

    const renderTabContent = (type) => {
        // TODO: User recipes api request
        if (type === 'user_public') {
            return (
                <Table size="small" pagination={{ pageSize: pageSize, total: meta.total }} onChange={onChange} columns={userPublicColumns} dataSource={recipes} />
            )
        }

        return (
            <Table size="small" pagination={{ pageSize: pageSize, total: meta.total, current: meta.current_page}} onChange={onChange} columns={columns} dataSource={recipes} />
        )
    }

    const onChange = (data) => {
        setPageSize(data.pageSize);
        loadRecipes(data.current);
    };

    const onSearch = (value) => {
        setSearch(value)
        loadRecipes(null, value);
    }

    const onSearchId = (value) => {
        navigate('/recipe/' + value)
    }

    const changeTab = (value) => {
        setActive(value);
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Recipes</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <Row type="flex" justify="space-between">
                    <h1>Recipes ({meta.total})</h1>

                    <Space>
                        <Search placeholder="Recipe ID" onSearch={onSearchId} style={{width: 140}}/>
                        <Search placeholder="Search recipe" onSearch={onSearch} style={{width: 200}}/>
                        <Button type={"primary"}><Link to="/create-recipe">Create recipe</Link></Button>
                    </Space>
                </Row>

                <Row type="flex" justify="end">
                </Row>

                <Tabs defaultActiveKey="1" onChange={changeTab}>
                    <TabPane tab="Fitlap" key="fitlap">
                        {renderTabContent('fitlap')}
                    </TabPane>
                    <TabPane tab="User" key="user">
                        {renderTabContent('user')}
                    </TabPane>
                    <TabPane tab="User Public" key="user_public">
                        {renderTabContent('user_public')}
                    </TabPane>
                </Tabs>
            </div>
        </Content>
    );
}

export default Recipes;