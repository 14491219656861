import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Form, Input, Layout, message, Popconfirm, Skeleton, Space, Table, Tabs, Tag} from 'antd';
import {Link, useParams} from "react-router-dom"
import Api from "../../api";
import {CheckCircleOutlined, DeleteOutlined, EditOutlined, SaveOutlined} from "@ant-design/icons";
import {redirect} from "../../helpers";

const {TabPane} = Tabs;
const {Content} = Layout;

const CustomUnitView = () => {
    const params = useParams();
    const [customUnit, setCustomUnit] = useState([]);



    useEffect(() => {
        getCustomUnit()
    }, [])

    const getCustomUnit = () => {
        Api.get('/admin/custom-units/' + params.id).then(res => {
            setCustomUnit(res.data.data)
        })
    }
    const onFinish = (values: any) => {
        let data = {"singular": values.singular, "plural": values.plural}
        Api.put("/admin/custom-units/" + customUnit.id, data).then(res => {
            setCustomUnit(res.data.data)
            return message.success('Custom unit updated');
        }).catch(e => {
            return message.error('Custom unit update failed');
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        return message.error('Failed: please fix the shown errors');
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (value, record) => <Space><span>{record.id}</span></Space>,
        },
        {
            title: 'Ingredient',
            dataIndex: 'original_name',
            key: 'original_name',
            render: (original_name, record) => <Link to={"/ingredient/" + record.id}>{record.original_name}</Link>,
            width: 200,
        },
    ];


    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/ingredients">Ingredients</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/ingredients/custom-units">Custom Units</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{customUnit.singular} / {customUnit.plural}</Breadcrumb.Item>
            </Breadcrumb>

            <h2>Custom Unit</h2>
            <div className="site-layout-card">

                {!customUnit.singular || !customUnit.plural ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Form
                        name="group"
                        initialValues={{
                            singular: customUnit.singular,
                            plural: customUnit.plural,
                        }}
                        autoComplete="off"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >

                        <Form.Item
                            label="Singular"
                            name="singular">
                            <Input type={"text"}/>
                        </Form.Item>

                        <Form.Item
                            label="Plural"
                            name="plural">
                            <Input type={"text"}/>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                                Save
                            </Button>
                        </Form.Item>

                    </Form>
                )}


                <Table columns={columns} dataSource={customUnit.ingredients} pagination={false}></Table>
            </div>
        </Content>
    );
}

export default CustomUnitView;