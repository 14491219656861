import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Row,
    Col,
    Input,
    Button,
    Skeleton,
    List,
    Form,
    Checkbox,
    Space,
    Select,
    message, Alert
} from 'antd';
import {useParams, Link} from 'react-router-dom';
import Api from "../api";
import TextArea from "antd/es/input/TextArea";
import ReactQuill, {Quill} from "react-quill";
import Item from "antd/lib/list/Item";
import "quill-emoji/dist/quill-emoji.css";
import * as Emoji from "quill-emoji";

const {Content} = Layout;

const AdventView = () => {
    const params = useParams();
    const [advent, setAdvent] = useState('');
    const [value, setValue] = useState('');
    const [points, setPoints] = useState('');
    const types = [
        {label: 'Show text', value: 'text'},
        {label: 'Give user points', value: 'points'},
        {label: 'Add +1 days to user account', value: 'days'}
    ]

    useEffect(() => {
        Api.get('/admin/advent-calendar/' + params.id).then(response => {
            setAdvent(response.data);
            console.log(response.data)
     })
    }, [])

    const onFinish = (values: any) => {
        console.log("kana")
        console.log(values)
        Api.put('/admin/advent-calendar/' + advent.id,values).then(response => {
            setAdvent(response.data);
            return message.success("Advent day updated");
        }).catch(e => {
            return message.error('Updating failed');
        });
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
            ['clean'],
            ['emoji']
        ],
            "emoji-toolbar": true,
    },

        formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link',
            'emoji'
        ]

    const handleFormValuesChange = (changedValues) => {
        const fieldName = Object.keys(changedValues)[0];

        setPoints(fieldName)
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/advent-calendar">Advent</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">

                <h1>Advent</h1>
                
                {!advent ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (

                    <Form
                        name="advent"
                        labelCol={{span: 8}}
                        onFinish={onFinish}
                        wrapperCol={{span: 16}}
                        autoComplete="off"
                        initialValues={{
                            gift_type: advent.gift_type,
                            points: advent.points
                        }}
                        onValuesChange={handleFormValuesChange}
                    >

                        <Alert
                            message="Points value"
                            description="0 = move to next level |  >0 = number of points to be given"
                            type="warning"
                            showIcon
                            closable
                        />

                        <Form.Item label="Type" name="gift_type">
                            <Select options={types}>
                            </Select>
                        </Form.Item>


                        <Form.Item label="Points" name="points" initialValue={advent.points}>
                                      <Input type="number"></Input>
                        </Form.Item>



                        <Form.Item label="Gift body" name="gift" initialValue={advent.gift}>
                            <ReactQuill modules={modules} formats={formats} value={advent.gift} onChange={setValue} />
                        </Form.Item>



                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Salvesta
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </Content>
    );
}

export default AdventView;