import React, {useEffect, useState} from "react";
import {
    Layout, Breadcrumb, Row, Col, Input, Button, Skeleton, Form, InputNumber, Table, Switch, Collapse, message, Space, Popconfirm, Tag, Typography
} from 'antd';
import {useParams, Link} from 'react-router-dom';
import Api from "../api";
import {DeleteOutlined, CheckCircleOutlined, FileSearchOutlined} from "@ant-design/icons";

const {Content} = Layout;

const Levels = () => {
    const params = useParams();
    const [levels, setLevels] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');


    useEffect(() => {
        loadSettings();
    }, [])

    const loadSettings = () => {
        Api.get('/admin/levels/settings').then(response => {
            setLevels(response.data.levels);
        })
    }

    const updateLevels = (dataObject) => {
        Api.put('/admin/levels/settings', dataObject).then(response => {
            setLevels(response.data);
        })
    }

    const EditableCell = ({
                              editing,
                              dataIndex,
                              title,
                              inputType,
                              record,
                              index,
                              children,
                              ...restProps
                          }) => {
        const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const isEditing = (record) => record.level === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            title: '',
            badge: '',
            reward: '',
            ...record,
        });
        setEditingKey(record.level);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            console.log(row)
            const newData = [...levels];
            const index = newData.findIndex((item) => key === item.level);

            if (index > -1) {
                const item = newData[index];
                console.log(item)
                newData.splice(index, 1, { ...item, ...row });
                console.log(newData)
                updateLevels(newData);
                setLevels(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setLevels(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        {
            title: 'Level #',
            dataIndex: 'level',
            width: '5%',
            editable: false,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            width: '15%',
            editable: true,
        },
        {
            title: 'Points (total needed)',
            dataIndex: 'points',
            width: '15%',
            render: (points, record) => <Space><span>{points} ({record.points_needed})</span></Space>,
            editable: false,
        },
        {
            title: 'Reward',
            dataIndex: ['reward', 'days'],
            width: '15%',
            render: (reward, record) => <Space><span>+{reward} day</span></Space>,
            editable: true,
        },{
            title: 'Type',
            dataIndex: ['reward', 'type'],
            width: '15%',
            editable: true,
            hidden: true,
        },
        {
            title: 'operation',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Typography.Link
                onClick={() => save(record.level)}
                style={{
                    marginRight: 8,
                }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Edit
                    </Typography.Link>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'age' ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),

        };
    });
    return (

        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Levels</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">

                <h1>Levels</h1>

                {!levels ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Form form={form} component={false}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            bordered
                            dataSource={levels}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                            pagination={false}
                        />
                    </Form>
                )}
            </div>
        </Content>
    );
};


export default Levels;