import React, {useState} from "react";
import {
    Form,
    Input,
    Button,
    Modal,
    message,
    InputNumber,
    Switch, Collapse,Space
} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import Api from "../api";

const NewBarcodeIngredient = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { Panel } = Collapse;
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const RenderForm = () => {
        const onFinish = (values: any) => {
            console.log('Success:', values);
            // Comment content
            const data = {
                barcode: values.barcode,
                name: values.name,
                energy: values.energy,
                carbohydrate: values.carbohydrate,
                protein: values.protein,
                fat: values.fat,
                visible: values.visible,
                nutridata: {
                    fiber: values.fiber
                }
            };

            Api.post("/v2/ingredients", data).then(res => {
                setIsModalVisible(false);
            });

            return message.error('Ingredient added');
        };

        const onChange = (e) => {

        }

        const checkBarcode = (e) => {
            const barcode = e.target.value;
            if(barcode.length >= 8) {
                Api.get('/admin/barcodes/' + barcode ).then(response => {
                    if(response.data === 1) {
                        return message.error('Failed: Barcode already exists!');
                    }
                })
            }
        }

        const onFinishFailed = (errorInfo: any) => {
            return message.error('Failed: please fix the shown errors');
        };

        return (
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >

                <Form.Item
                    label="Barcode"
                    name="barcode"
                    rules={[{ required: true, message: 'Please insert a barcode!' }]}
                >
                    <Input onChange={onChange} onBlur={(e) => checkBarcode(e)}/>
                </Form.Item>

                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please insert the ingredient name!' }]}
                    >
                        <Input />
                    </Form.Item>

                <Form.Item
                    label="Energy (kcal)"
                    name="energy"
                    rules={[{ required: true, message: 'Please insert energy values!' }]}
                >
                    <InputNumber
                        defaultValue="0"
                        min="0"
                        step="0.01"
                        stringMode
                    />
                </Form.Item>

                <Form.Item
                    label="Carbohydrates (g)"
                    name="carbohydrate"
                    rules={[{ required: true, message: 'Please insert carbohydrates values!' }]}
                >
                    <InputNumber
                        defaultValue="0"
                        min="0"
                        step="0.01"
                        stringMode
                    />
                </Form.Item>

                <Form.Item
                    label="Protein (g)"
                    name="protein"
                    rules={[{ required: true, message: 'Please insert protein values!' }]}
                >
                    <InputNumber
                        defaultValue="0"
                        min="0"
                        step="0.01"
                        stringMode
                    />
                </Form.Item>

                <Form.Item
                    label="Fat (g)"
                    name="fat"
                    rules={[{ required: true, message: 'Please insert fat values!' }]}
                >
                    <InputNumber
                        defaultValue="0"
                        min="0"
                        step="0.01"
                        stringMode
                    />
                </Form.Item>

                <Form.Item label="Visible" name="visible" valuePropName="checked">
                    <Switch />
                </Form.Item>

                <Collapse defaultActiveKey={['1']}>
                    <Panel header="Nutridata (optional)" key="1">
                        <Form.Item label="Fiber (g)" name="fiber">
                            <InputNumber defaultValue="0" min="0" step="0.01" stringMode/>
                        </Form.Item>
                    </Panel>
                </Collapse>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        );
    };


    return (
        <>

            <Space>
                <Button className="add-ingredient-btn" type="primary" onClick={showModal}>
                    <PlusOutlined/> Add barcode
                </Button>
            </Space>


            <Modal title="Add barcode"
                   visible={isModalVisible}
                   onCancel={handleCancel}
                   footer={null}
            >
                <RenderForm/>
            </Modal>
        </>

    );
};

export default NewBarcodeIngredient;