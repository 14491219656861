import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Form, Input, Layout, message, Popconfirm, Skeleton, Space, Table, Tabs, Tag} from 'antd';
import {Link, useParams} from "react-router-dom"
import Api from "../api";
import IngredientSearch from "../components/ingredient-search";
import {CheckCircleOutlined, DeleteOutlined} from "@ant-design/icons";

const {TabPane} = Tabs;
const {Content} = Layout;

const SubstituteGroupView = () => {
    const params = useParams();
    const [group, setGroup] = useState([]);
    const [search, setSearch] = useState([]);


    useEffect(() => {
        getGroup()
    }, [])

    const getGroup = () => {
        Api.get('/admin/substitute-groups/' + params.id).then(res => {
            setGroup(res.data.data)
            console.log(res.data.data)
        })
    }
    const onFinish = (values: any) => {
        let data = {"name": values.label}
        Api.put("/admin/substitute-groups/" + group.value, data).then(res => {
            setGroup(res.data.data)
            return message.success('Substitute group updated');
        }).catch(e => {
            return message.error('Substitute group update failed');
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        return message.error('Failed: please fix the shown errors');
    };

    const addIngredientToGroup = (ingredient) => {
        Api.post('/admin/substitute-groups/' + group.value, {"ingredient_id": ingredient}).then(res => {
            message.success('Ingredient added to substitute group');
            getGroup()
        })
    }

    const removeIngredientFromGroup = (ingredient) => {
        Api.delete('/admin/substitute-groups/' + group.value + '/' + ingredient).then(res => {
            message.success('Ingredient removed from substitute group');
            getGroup()
        })
    }

    const columns = [
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <Popconfirm
                        title={`Are you sure you want to delete: ${record.original_name}?`}
                        okText="Yes"
                        onConfirm={() => removeIngredientFromGroup(record.id)}
                        cancelText="No"
                    >
                        <a key="delete-comment" className="delete-comment"><DeleteOutlined/></a>
                    </Popconfirm>
                </Space>,
            width: '2%',
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (text, record) => record.id,
        },
        {
            title: 'Name',
            dataIndex: 'original_name',
            key: 'original_name',
            width: 30,
            render: (text, record) => <Link to={"/ingredient/" + record.id}>{record.original_name}</Link>,
        },
    ];


    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/ingredients">Ingredients</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/ingredients/substitute-groups">Substitute Groups</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{group.label}</Breadcrumb.Item>
            </Breadcrumb>

            <h2>Group settings</h2>
            <div className="site-layout-card">

                {!group.label ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Form
                        name="group"
                        initialValues={{
                            label: group.label,
                            short_name: group.value
                        }}
                        autoComplete="off"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >

                        <Form.Item
                            label="Name"
                            name="label">
                            <Input type={"text"}/>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>

            <h2>Add ingredient</h2>
            <IngredientSearch searchResponse={search} setSearchResponse={setSearch} onClickEvent={addIngredientToGroup}
                              onlyId={true}/>

            <h2 style={{marginTop: '1em'}}>Ingredients</h2>
            <Table columns={columns} dataSource={group.ingredients} pagination={false}></Table>
        </Content>
    );
}

export default SubstituteGroupView;