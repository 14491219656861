import {Link} from "react-router-dom";
import {Table, Tag} from "antd";
import moment from "moment";
import React, {useState} from "react";
import InvoiceModal from "./invoice-modal";

const InvoicesTable = ({onChange, loading, meta, invoices}) => {
    const [selectedInvoice, setSelectedInvoice] = useState(null); // State to track selected invoice
    const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility

    const handleInvoiceClick = (invoice) => {
        setSelectedInvoice(invoice);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedInvoice(null);
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case 0:
                return <Tag>Pending</Tag>;
            case 1:
                return <Tag color="green">Paid</Tag>;
            case 2:
                return <Tag color="yellow">Recurring Active</Tag>;
            case 3:
                return <Tag color="red">Recurring Cancelled</Tag>;
            case 4:
                return <Tag color="red">Recurring Failed</Tag>;
            case 5:
                return <Tag>Recurring Finished</Tag>;
            case 7:
                return <Tag>Chargeback</Tag>;
            case 8:
                return <Tag>Not confirmed</Tag>;
            default:
                return 'Unknown';
        }
    };


    return (
        <div>
            <Table style={{width: "100%"}} size="small" loading={loading}
                   onChange={onChange}
                   pagination={meta ? {pageSize: 20, total: meta.total, current: meta.current_page} : false}
                   dataSource={invoices}
                   columns={[
                       {
                           title: 'invoice_id',
                           dataIndex: 'invoice_id',
                           key: 'invoice_id',
                           render: (value, record) => (
                               <a onClick={() => handleInvoiceClick(record)}>{value}</a>
                           )
                       },
                       {
                           title: 'user_id',
                           dataIndex: 'user_id',
                           key: 'user_id',
                           render: (value, record) => (
                               <Link to={'/user/' + value}>{value}</Link>
                           )

                       },
                       {
                           title: 'first_period',
                           dataIndex: 'first_period',
                           key: 'first_period',
                       },
                       {
                           title: 'first_total',
                           dataIndex: 'first_total',
                           key: 'first_total',
                           render: (value, record) => <span>{value} {record.currency}</span>
                       },
                       {
                           title: 'coupon_code',
                           dataIndex: 'coupon_code',
                           key: 'coupon_code',
                           render: (value, record) => value ? <Tag color="orange">{value}</Tag> : ""
                       },
                       {
                           title: 'paysys_id',
                           dataIndex: 'paysys_id',
                           key: 'paysys_id',
                       },
                       {
                           title: 'tm_added',
                           dataIndex: 'tm_added',
                           key: 'tm_added',
                           render: value => <span>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</span>
                       },
                       {
                           title: 'status',
                           dataIndex: 'status',
                           key: 'status',
                           render: status => getStatusLabel(status)
                       },
                   ]}/>

            <InvoiceModal isModalVisible={isModalVisible} selectedInvoice={selectedInvoice} handleModalClose={handleModalClose}/>
        </div>
    )
}

export default InvoicesTable;