import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    Checkbox,
    Divider,
    Layout,
    Row,
    Table,
    Tag,
    Typography,
    Modal,
    Button,
    DatePicker,
    Select, Space
} from "antd";
import moment from "moment/moment";
import Api from "../api";
import {Link} from "react-router-dom";
import InvoiceModal from "../components/invoice-modal";
import InvoicesTable from "../components/invoices-table";

const {Title} = Typography;
const {Content} = Layout;
const { RangePicker } = DatePicker;

const Invoices = () => {
    // State to manage whether to show free invoices
    const [showFree, setShowFree] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [meta, setMeta] = useState(false);
    const [status, setStatus] = useState('');
    const [dates, setDates] = useState("");
    const [page, setPage] = useState(1);

    useEffect(() => {
        loadInvoices();
    }, [status, dates, page]);

    const loadInvoices = () => {
        setLoading(true);

        let startDate = dates.startDate;
        let endDate = dates.endDate;

        let dateparams = startDate && endDate ? `&start_date=${startDate}&end_date=${endDate}` : '';
        let params = status !== '' ? ('&status=' + status) : '';

        Api.get('/admin/latest-invoices?page=' + page + params + dateparams).then(res => {
            setMeta(res.data);
            setInvoices(res.data.data);
            setLoading(false);
        }).catch(error => {
            console.error("Error fetching data:", error);
        });
    }

    const onChange = (data) => {
        setPage(data.current);
    };

    console.log(page);

    const handleRangeChange = (dates, dateStrings) => {
        if (dates) {
            const startDateString = dateStrings[0];
            const endDateString = dateStrings[1];

            setPage(1);
            setDates({
                startDate: startDateString,
                endDate: endDateString,
            })
        } else {
            setPage(1);
            setDates(false)
        }
    };

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Invoices</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">

                <Divider orientation="left">
                    <Title style={{margin: 0}} level={4}>Invoices ({meta.total})</Title>

                </Divider>

                <Row type="flex" justify="end">
                    <Space>
                        <Select onChange={(value) => {
                            setPage(1)
                            setStatus(value)
                        }} defaultValue={'-- Choose status --'} style={{minWidth: 160}}>
                            <Select.Option value={''}>-- Choose status --</Select.Option>
                            <Select.Option value={0}>Pending</Select.Option>
                            <Select.Option value={1}>Paid</Select.Option>
                            <Select.Option value={2}>Recurring active</Select.Option>
                            <Select.Option value={3}>Recurring cancelled</Select.Option>
                            <Select.Option value={4}>Recurring failed</Select.Option>
                            <Select.Option value={5}>Recurring finished</Select.Option>
                            <Select.Option value={7}>Chargeback</Select.Option>
                        </Select>

                        <RangePicker onChange={handleRangeChange}/>
                    </Space>
                </Row>

                <br/>

                <InvoicesTable invoices={invoices} onChange={onChange} loading={loading} meta={meta}/>
            </div>
        </Content>
    )
}

export default Invoices;