import React, {useEffect, useState} from "react";
import {Layout, Breadcrumb, List, Skeleton, Row, Col} from 'antd';
import Api from "../api.js";
import {useParams} from "react-router-dom"

const {Content} = Layout;

const Settings = () => {
    const params = useParams();
    const [user, setUser] = useState('');

    useEffect(() => {
        Api.get('/v1/user').then(res => {
            setUser(res.data.data);
        })
    }, [])

    console.log(user);

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Settings</Breadcrumb.Item>
            </Breadcrumb>

            <Row>
                <Col span={24}>
                    <div className="site-layout-card">
                        <h1>Settings</h1>

                        {!user ? (
                            <Skeleton title={true} loading={true} active/>
                        ) : (
                            <List
                                dataSource={Object.entries(user)}
                                renderItem={item => {
                                    return (
                                        <List.Item>
                                            <strong>{item[0]}:</strong> {JSON.stringify(item[1])}
                                        </List.Item>
                                    )
                                }}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </Content>
    );
}

export default Settings;