import React, {useState} from "react";
import {Form, Input, Button, Space, Modal, message, InputNumber} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import Api from "../api";

const NewTrainingVideo = ({loadVideos}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [video, setVideo] = useState(false);


    const showModal = () => {
        setIsModalVisible(true);
    };

    const onFinish = (values: any) => {
        Api.post("/admin/training-videos/videos", values).then(res => {
            setIsModalVisible(false);
            loadVideos()
            return message.success('Success! New video added!');
        }).catch(e => {
            return message.error(`${e.message}`);
        })
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <>
            <Button className="add-translation-btn" type="primary" onClick={showModal}>
                <PlusOutlined/> Add new video
            </Button>

            <Modal title="Add new video"
                   visible={isModalVisible}
                   onCancel={handleCancel}
                   footer={null}
            >
                <Form
                    name="add-training-video"
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    onFinish={onFinish}
                    initialValues={{remember: true}}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Video URL"
                        name="url"
                        rules={[{required: true, message: 'Please insert video URL'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[{required: true, message: 'Please insert video title'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Runtime"
                        name="time"
                        rules={[{required: true, message: 'Please insert video runtime'}]}>
                        <InputNumber defaultValue={0}/>
                    </Form.Item>
                    <Form.Item
                        label="Video image URL"
                        name="image"
                        >
                        <Input/> <small>Youtube embed links will get the image automatically(leave this blank)</small>
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <TextArea/>
                    </Form.Item>
                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    );
};

export default NewTrainingVideo;