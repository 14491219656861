import React, {useEffect, useState} from "react";
import {Breadcrumb, Input, Layout, message, Popconfirm, Space, Table, Tabs, Tag} from 'antd';
import {Link} from "react-router-dom"
import Api from "../api";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined} from '@ant-design/icons';
import NewBarcodeIngredient from "../components/new-barcode-ingredient";
import IngredientList from "../components/ingredient-list";
import IngredientSearch from "../components/ingredient-search";

const {TabPane} = Tabs;
const {Search} = Input;
const {Content} = Layout;

const FreewareIngredientsView = () => {
    const [ingredients, setIngredients] = useState([]);
    const [active, setActive] = useState('fitlap');
    const [meta, setMeta] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [search, setSearch] = useState("");
    const [searchResponse, setSearchResponse] = useState([]);

    useEffect(() => {
        loadIngredients();
    }, [active])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 30,
            render: (verified, record) => <Space><span>{record.id}</span><span><CheckCircleOutlined
                style={record.verified === 1 ? {color: 'green'} : {color: "red"}}/></span></Space>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => {
                return (
                    <Link to={"/ingredient/" + record.id}>{name}</Link>
                )
            },
            width: 200,
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <Popconfirm
                        title={`Are you sure you want to delete: ${record.original_name}?`}
                        okText="Yes"
                        onConfirm={() => removeIngredient(record.id)}
                        cancelText="No"
                    >
                        <a key="delete-comment" className="delete-comment"><DeleteOutlined/> delete</a>
                    </Popconfirm>
                </Space>,
            width: 30,
        },
    ];

    const loadIngredients = (nextPage, search) => {
        let params = `&per_page=${pageSize}`

        if (search) {
            params += `&keywords=${search}`;
        }
        if (nextPage) {
            params += `&page=${nextPage}`;
        } else {
            params += '&page=1';
        }

        params += '&order_by=id';

        Api.get('/admin/ingredients/freeware').then(response => {
            setPage(response.data.current_page);
            setMeta(response.data.meta);
            setIngredients(response.data.data);
        })
    };

    const renderTabContent = (type) => {
        return (
            <Table pagination={{pageSize: pageSize, total: meta.total}} onChange={onChange} columns={columns}
                   dataSource={ingredients}/>
        )
    }

    const onSearch = (value) => {
        if (value.length > 2) {
            Api.post('admin/ingredients/search', {key: value}).then(res => {
                setSearchResponse(res.data);
            })
        } else {
            setSearchResponse([])
        }

    }

    const searchIngredient = (value) => {
        Api.post('/admin/ingredients/search', {key: value}).then(response => {
            setSearchResponse(response.data);
            console.log(response.data)
        })
    }
    const onChange = (data) => {
        setPageSize(data.pageSize);
        loadIngredients(data.current);
    };

    const changeTab = (value) => {
        setActive(value);
    }

    const addIngredient = (ingredient) => {
        Api.post('/admin/ingredients/freeware/' + ingredient).then(res => {
            loadIngredients();
            message.success('Ingredient added to freeware');
        })
    }

    const removeIngredient = (ingredient) => {
        Api.delete('/admin/ingredients/freeware/' + ingredient).then(res => {
            loadIngredients();
            message.success('Ingredient deleted from freeware');
        })
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Ingredients</Breadcrumb.Item>
                <Breadcrumb.Item>Freeware</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <h1>Ingredients ({meta.total})</h1>

                <div style={{marginTop:"40px", marginBottom:"20px"}}>
                    <IngredientSearch searchResponse={searchResponse} setSearchResponse={setSearchResponse} onClickEvent={addIngredient} onlyId={true}/>
                </div>


                <Tabs defaultActiveKey="1" onChange={changeTab}>
                    <TabPane tab="Freeware ingredients" key="freeware">
                        {renderTabContent('freeware')}
                    </TabPane>
                </Tabs>
            </div>
        </Content>
    );
}

export default FreewareIngredientsView;