import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    List,
    Skeleton,
    Row,
    Col,
    Card,
    Form,
    Input,
    InputNumber,
    Select,
    Button,
    message
} from 'antd';
import Api from "../api.js";
import {useParams} from "react-router-dom"
import StepsChallange from "../components/reports/steps-challenge";

const {Content} = Layout;

const Reports = () => {
    const params = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
    }, [])

    const onFinish = (values: any) => {
        setLoading(true)
        Api.get("/admin/reports/visits?email=" + values.email + "&days=" + values.days + "&timeframe=" + values.more_than, values).then(res => {
            setLoading(false)
            return message.success('Report sent to ' + values.email);
        });
    };

    const onFinishGetCustomersList = (values: any) => {
        setLoading(true)
        Api.get("/admin/reports/customer-list?email=" + values.email + "&active=" + values.status).then(res => {
            setLoading(false)
            return message.success('Report sent to ' + values.email);
        }).catch(e => {
            setLoading(false)
            return message.error(`${e.message}`);
        })
    };


    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>User</Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>


                <Col span={24}>
                    <div className="site-layout-card">
                        <h1>Reports</h1>

                        <Row gutter={16}>
                            <Col span={8}>
                                <Card title="App visited(Geiu)">
                                    <Form
                                        name="amember_visits"
                                        labelCol={{span: 8}}
                                        wrapperCol={{span: 16}}
                                        initialValues={{remember: true}}
                                        onFinish={onFinish}
                                        autoComplete="off"
                                    >
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[{required: true, message: 'Email on nõutud'}]}>
                                            <Input/>
                                        </Form.Item>

                                        <Form.Item
                                            label="Days"
                                            name="days">
                                            <InputNumber
                                                min="1"
                                                step="1"
                                            />
                                        </Form.Item>

                                        <Form.Item label="Timeframe" name="more_than">
                                            <Select
                                                defaultValue="false"
                                                style={{width: 120}}
                                                options={[{value: 'false', label: 'Within'}, {
                                                    value: 'true',
                                                    label: 'More than'
                                                }]}
                                            />
                                        </Form.Item>

                                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                            <Button type="primary" htmlType="submit" loading={loading}>
                                                Generate
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <StepsChallange></StepsChallange>
                            </Col>
                            <Col span={8}>
                                <Card title="Active/Inactive customers">
                                    <Form
                                        name="amember_customers_list"
                                        labelCol={{span: 8}}
                                        wrapperCol={{span: 16}}
                                        initialValues={{remember: true}}
                                        onFinish={onFinishGetCustomersList}
                                        autoComplete="off"
                                    >
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[{required: true, message: 'Email on nõutud'}]}>
                                            <Input/>
                                        </Form.Item>

                                        <Form.Item label="Status" name="status" rules={[{required: true, message: 'Status is required'}]}>
                                            <Select
                                                defaultValue={true}
                                                style={{width: 120}}

                                                options={[{value: 'false', label: 'Inactive'}, {
                                                    value: 'true',
                                                    label: 'Active'
                                                }]}
                                            />
                                        </Form.Item>

                                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                                            <Button type="primary" htmlType="submit" loading={loading}>
                                                Generate
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Col>
        </Content>
    );
}

export default Reports;