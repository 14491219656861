import React, {useEffect, useState} from "react";
import {Form, Input, Button, Space, Modal, message, InputNumber, Switch, Checkbox} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import Api from "../api";

const NewTrainingVideoCategory = ({setCategories, categories, loadCategories, editCategory, setEditCategory}) => {
    const [isModalVisible, setIsModalVisible] = useState(!!editCategory);
    const [video, setVideo] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    useEffect(() => {
        if (editCategory) {
            setIsModalVisible(true);
        }
    }, [editCategory])

    const onFinish = (values: any) => {
        const type = editCategory ? 'put' : 'post';

        console.log(values);

        Api[type]("/admin/training-videos/categories" + (editCategory ? `/${editCategory.id}` : ''), values).then(res => {
            setIsModalVisible(false);
            loadCategories()
            return message.success(editCategory ? 'Category updated' : 'Success! New category added!');
        }).catch(e => {
            return message.error(`${e.message}`);
        })
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setEditCategory(false);
    };

    return (
        <>
            <Button className="add-translation-btn" type="primary" onClick={showModal}>
                <PlusOutlined/> Add new category
            </Button>

            {isModalVisible && (
                <Modal title="Add new category"
                       open={isModalVisible}
                       onCancel={handleCancel}

                       footer={null}
                >
                    <Form
                        key={editCategory}
                        name="add-training-video-category"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        onFinish={onFinish}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Name"
                            name="name"
                            initialValue={editCategory.name}
                            rules={[{ required: true, message: 'Please insert category name' }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Icon (url)"
                            name="icon"
                            initialValue={editCategory.icon}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Lihasgrupp"
                            name="is_muscle_group"
                            initialValue={editCategory.is_muscle_group}
                            valuePropName={"checked"}
                        >
                            <Checkbox defaultChecked={editCategory.is_muscle_group} />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </>

    );
};

export default NewTrainingVideoCategory;