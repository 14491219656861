import React, { useEffect, useState } from "react";
import Api from "../api";
import { Breadcrumb, Button, Checkbox, DatePicker, Layout, message, Row, Spin, Table, Tag, Typography } from "antd";
import moment from "moment";
import dayjs from "dayjs";

const { Text } = Typography;
const { Content } = Layout;

const ErrorLog = () => {
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        setLoading(true);

        Api.get('/admin/error-log?date=' + date).then(res => {
            const logData = res.data.log.reverse();
            setData(logData);
            setFilteredData(filterData(logData, filters));
            setLoading(false);
        }).catch(err => {
            setData([]);
            setLoading(false);
            message.error('Error log 404');
        })
    }, [date]);

    useEffect(() => {
        setFilteredData(filterData(data, filters));
    }, [filters, data]);

    const onChange = (date, dateString) => {
        setDate(dateString);
    };

    const getStatusLabel = (status) => {
        switch (status) {
            case 'production.DEBUG':
                return <Tag color="yellow">debug</Tag>;
            case 'production.INFO':
                return <Tag color="blue">info</Tag>;
            case 'production.ERROR':
                return <Tag color="red">error</Tag>;
            default:
                return 'Unknown';
        }
    };

    const handleFilterChange = (checkedValues) => {
        setFilters(checkedValues);
    };

    const filterData = (data, filters) => {
        if (filters.length === 0) return data;
        return data.filter(item => filters.includes(item.level));
    };

    const filterOptions = [
        { label: 'debug', value: 'production.DEBUG' },
        { label: 'info', value: 'production.INFO' },
        { label: 'error', value: 'production.ERROR' },
    ];

    return (
        <Content style={{ margin: '0 16px' }}>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Error log</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <Row>
                    <DatePicker loading={loading} defaultValue={dayjs(date, 'YYYY-MM-DD')} onChange={onChange} />
                </Row>

                <br />

                <div>
                    <div style={{marginBottom: 20}}>
                        <Checkbox.Group options={filterOptions} onChange={handleFilterChange} />
                    </div>

                    <Table
                        size="small"
                        loading={loading}
                        pagination={false}
                        columns={[
                            {
                                title: 'timestamp',
                                dataIndex: 'timestamp',
                                render: (text) => <Text code>{moment(text).format('HH:mm:ss')}</Text>,
                                width: 100
                            },
                            {
                                title: 'level',
                                dataIndex: 'level',
                                render: (text) => getStatusLabel(text),
                            },
                            {
                                title: 'message',
                                dataIndex: 'message',
                                render: (text) => <Text code><span dangerouslySetInnerHTML={{ __html: text }} /></Text>
                            }
                        ]}
                        dataSource={filteredData}
                    />
                </div>
            </div>
        </Content>
    )
}

export default ErrorLog;