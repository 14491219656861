import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Button,
    Skeleton,
    Form,
    Space,
    Select,
    message, Input
} from 'antd';
import {useParams, Link} from 'react-router-dom';
import Api from "../api";
import ReactQuill, {Quill} from "react-quill";
import "quill-emoji/dist/quill-emoji.css";
import moment from "moment";

const {Content} = Layout;

const TaskView = () => {
    const params = useParams();
    const [task, setTask] = useState('');
    const [value, setValue] = useState('');
    const types = [
        {label: 'Show text', value: 'text'},
        {label: 'Move user to next level', value: 'points'},
        {label: 'Add +1 days to user account', value: 'days'}
    ]

    useEffect(() => {
        Api.get('/admin/tasks/' + params.id).then(response => {
            setTask(response.data);
            console.log(response.data)
     })
    }, [])

    const onFinish = (values: any) => {
        console.log(values)
        Api.put('/admin/tasks/' + task.id,values).then(response => {
            setTask(response.data);
            return message.success("Task updated");
        }).catch(e => {
            return message.error('Updating failed');
        });
    };

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
            ['clean'],
            ['emoji']
        ],
            "emoji-toolbar": true,
    },

        formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link',
            'emoji'
        ]



    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/tasks">Tasks</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">

                <h1>Task</h1>
                
                {!task ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Form
                        name="task"
                        labelCol={{span: 8}}
                        onFinish={onFinish}
                        wrapperCol={{span: 16}}
                        autoComplete="off"
                        initialValues={{
                            title: task.title,
                            description: task.description,
                        }}
                    >

                        <Form.Item label="Date">
                            <h3>{moment(task.date).format('DD-MM-YYYY')} - {moment(task.date).format('dddd')}</h3>
                        </Form.Item>

                        <Form.Item
                            label="Title"
                            name="title"
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item label="Description" name="description">
                            <ReactQuill modules={modules} formats={formats}  onChange={setValue} />
                        </Form.Item>



                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Salvesta
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </Content>
    );
}

export default TaskView;