import React, {useEffect, useState} from "react";
import {Alert, Breadcrumb, Button, Input, Layout, message, Popconfirm, Skeleton, Space, Table, Tabs, Tag} from 'antd';
import {Link} from "react-router-dom"
import Api from "../api";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {redirect} from "../helpers";
import moment from "moment";

const {TabPane} = Tabs;
const {Search} = Input;
const {Content} = Layout;

const NotificationsView = ({onClickEvent}) => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        loadNotifications();
    }, [])

    const loadNotifications = () => {
        Api.get('/admin/notifications').then(response => {
            setNotifications(response.data.data);
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: '8%',
            render: (value, record) => record.id,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (label, record) => record?.type,
            width: "10%",
        },
        {
            title: 'Image',
            dataIndex: 'image',
            render: (label, record) => record.image ? <img src={record.image} alt="notification image"/> : "",
            width: 200
        },
        {
            title: 'Title',
            dataIndex: 'title',
            render: (label, record) => record.title,
            width: 200,
        },
        {
            title: 'Content',
            dataIndex: 'content',
            render: (text, record) => <div dangerouslySetInnerHTML={{__html: text}}/>,
        },
        {
            title: 'Added',
            dataIndex: 'created_at',
            render: (label, record) => moment(record.created_at).format('DD-MM-YYYY H:m:s'),
            width: 200,
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <Link to={"/notifications/" + record.id}><EditOutlined/> edit</Link>
                </Space>,
            width: 30,
        },
    ];


    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
            </Breadcrumb>

            {!notifications ? (
                <Skeleton title={true} loading={true} active/>
            ) : (

                <>

                    <div className="site-layout-card">
                        <Space align={"center"}>
                            <h1>All notifications</h1>

                            <Button type="primary" onClick={() => {
                                redirect('/notifications/create')
                            }}>
                                Add Notification
                            </Button>
                        </Space>

                    </div>
                    <Alert style={{margin: '10px 0px'}}
                           message="Automatiseerituid teavitusi tohib muuta ainult arendajad!"
                           type="error"
                           showIcon={true}
                    />

                    <Table pagination={false} columns={columns}
                           dataSource={notifications}/>


                </>
            )}

        </Content>
    );
}

export default NotificationsView;