import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    Divider,
    Layout, Space,
    Table, Tag,
    Typography,
} from "antd";
import Api from "../api";
import {Link} from "react-router-dom";

const {Title} = Typography;
const {Content} = Layout;

const Wellness = () => {
    // State to manage whether to show free invoices
    const [wellnessLog, setWellnessLog] = useState([]);
    const [loading, setLoading] = useState(false);
    const [meta, setMeta] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        loadWellness();
    }, [page]);

    const loadWellness = () => {
        setLoading(true);

        Api.get('/admin/wellness-log?page=' + page).then(res => {
            setMeta(res.data.meta);
            setWellnessLog(res.data.data);
            setLoading(false);
        }).catch(error => {
            console.error("Error fetching data:", error);
        });
    }

    const onChange = (data) => {
        setPage(data.current);
    };

    const renderColumnTag = (value) => {
        if (value < 4) {
            return (<Tag color="red">{value}</Tag>)
        }

        if (value <= 7) {
            return (<Tag color="orange">{value}</Tag>)
        }

        return (<Tag color="green">{value}</Tag>)
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Wellness</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">

                <Divider orientation="left">
                    <Title style={{margin: 0}} level={4}>Wellness ({meta.total})</Title>

                </Divider>

                <br/>

                <Table size="small" onChange={onChange} loading={loading}
                       pagination={meta ? {pageSize: 20, total: meta.total, current: meta.current_page} : false}
                       columns={[
                           {title: 'id', dataIndex: 'id', key: 'id', width: 80},
                           {title: 'date', dataIndex: 'date', key: 'date', width: 140},
                           {title: 'user_id', dataIndex: 'user_id', key: 'user_id', render: (value, record) => <Link to={"/user/" + value}>{value}</Link>},
                           {title: 'sleep', dataIndex: 'sleep', key: 'sleep', render: (value, record) => renderColumnTag(value)},
                           {title: 'health', dataIndex: 'health', key: 'health', render: (value, record) => renderColumnTag(value)},
                           {title: 'energy', dataIndex: 'energy', key: 'energy', render: (value, record) => renderColumnTag(value)},
                       ]} dataSource={wellnessLog}/>
            </div>
        </Content>
    )
}

export default Wellness;