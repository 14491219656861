import React, {useState} from "react";
import {Breadcrumb, Button, Checkbox, Col, Input, Layout, List, message, Row} from 'antd';
import {Link, useParams} from 'react-router-dom';
import Api from "../api";
import NewBarcodeIngredient from "../components/new-barcode-ingredient";

const {Content} = Layout;
const {Search} = Input;

const BarcodeView = () => {
    const [barcode, setBarcode] = useState(true);
    const [marked, setMarked] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const params = useParams();

    const onSearch = (value) => {
        Api.get('/v2/ingredients?&keywords=' + value).then((res) => {
            setIngredients(res.data.data);
        });

        console.log(ingredients);
    }

    const onChange = (e) => {
        setBarcode(e.target.value)
    }


    const createBarcode = () => {

        if(Object.keys(marked).length === 0) {
            return message.error("Please select at least 1 ingredient")
        }

        const data = {
            barcode: barcode,
            ingredients: marked
        }

        Api.post("/admin/barcodes", data).then(res => {
            // Clear comment textarea
            setMarked([]);

            return message.success("Added barcode");
        });
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/barcode">Barcodes</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <h1>Add barcode to ingredients</h1>


                <Search placeholder="ingredient..." onSearch={onSearch} style={{width: 200, marginRight: 10}}/>
                <NewBarcodeIngredient/>
                <Row gutter={[48, 16]}>
                    <Col span={12} >
                        <List
                            header={<div><b>SEARCH</b></div>}
                            itemLayout="horizontal"
                            dataSource={ingredients}
                            renderItem={ingredient => {
                                return (
                                    <List.Item>
                                        <List.Item.Meta
                                            style={{alignItems: "center"}}
                                            description={<div>
                                                <Button type="primary" style={{marginRight: 10}} size='small' onClick={() => setMarked(oldArray => [...oldArray, ingredient])}>Lisa</Button>
                                                {ingredient.id} | {ingredient.name}
                                            </div>
                                            }
                                        />
                                    </List.Item>
                                )
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <List
                            header={<div><b>TO BE ADDED</b></div>}
                            itemLayout="horizontal"
                            dataSource={marked}
                            renderItem={ingredient => {
                                return (
                                    <List.Item>
                                        <List.Item.Meta
                                            style={{alignItems: "center"}}
                                            description={<div>
                                                {ingredient.id} | {ingredient.name}

                                            </div>
                                            }
                                        />
                                    </List.Item>
                                )
                            }}
                        />
                        <Input placeholder="Barcode..." style={{width: 200, marginRight: 10}} onChange={onChange}/>
                        <Button type={"primary"} className="main-btn" style={{marginRight:10}} onClick={() => createBarcode()}>Save barcode</Button>
                        <Button type={"default"} className="main-btn" onClick={() => setMarked([])}>Cancel</Button>
                    </Col>
                </Row>


            </div>
        </Content>
    );
}

export default BarcodeView;