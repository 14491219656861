import React, {useEffect, useState} from "react";
import {
    CartesianGrid,
    ResponsiveContainer,
    Tooltip as ChartTooltip,
    XAxis,
    Area,
    YAxis,
    ComposedChart
} from "recharts";
import {DatePicker, Row, Spin} from 'antd';
import moment from "moment";
import Api from "../../api";
import {LoadingOutlined} from "@ant-design/icons";
const { RangePicker } = DatePicker;

const DailyRegistrations = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        Api.get('/admin/daily-signups').then(res => {
            setData(res.data);
            setLoading(false);
        })
    }, [])

    const formatXAxis = (tickItem) => {
        return moment(new Date(tickItem)).format('D. MMM');
    };

    const handleRangeChange = (dates, dateStrings) => {
        if (dates) {
            const startDateString = dateStrings[0];
            const endDateString = dateStrings[1];

            loadData(startDateString, endDateString);
        } else {
            loadData();
        }
    };

    const loadData = (startDate, endDate) => {
        let query = startDate && endDate ? `?start_date=${startDate}&end_date=${endDate}` : null;

        setLoading(true);

        Api.get('/admin/daily-signups' + query).then(res => {
            setData(res.data);
            setLoading(false);
        })
    }


    return (
        <>
            <Row type="flex" justify="space-between" align="middle">
                <h1>Signups</h1>
                <RangePicker onChange={handleRangeChange}/>
            </Row>

            {loading && (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} className="spinner-loader" />
            )}

            <ResponsiveContainer className="goal-graph-container" width={"100%"}
                                 height={200}>
                <ComposedChart
                    height={200}
                    data={data}
                    syncId="registrationsChart"
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv5" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#efc207" stopOpacity={0.3}/>
                            <stop offset="95%" stopColor="#efc207" stopOpacity={0}/>
                        </linearGradient>
                    </defs>

                    <CartesianGrid strokeDasharray="3 3"/>

                    <XAxis tickFormatter={formatXAxis} type="category" dataKey="registration_date"/>

                    <YAxis width={50} type="number"/>

                    <ChartTooltip labelFormatter={(value) => (moment(new Date(value)).format('DD.MM.YYYY'))}/>

                    <CartesianGrid stroke="#f5f5f5"/>

                    <Area type="monotone" name={'Uued liitujad'} dataKey="user_count" stroke="#efc207"
                          fill="url(#colorUv5)"/>
                </ComposedChart>
            </ResponsiveContainer>
        </>
    );
}

export default DailyRegistrations;