import React, {useEffect, useState} from "react";
import {
    Breadcrumb,
    Button,
    Form,
    Input,
    Layout,
    message,
    Popconfirm,
    Select,
    Skeleton,
    Space,
    Table,
    Tabs,
    Tag
} from 'antd';
import {Link, useParams} from "react-router-dom"
import Api from "../api";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {redirect} from "../helpers";

const {TabPane} = Tabs;
const {Search} = Input;
const {Content} = Layout;

const MenuTemplateView = ({onClickEvent}) => {
    const params = useParams();
    const [template, setTemplate] = useState([]);
    const [recipes, setRecipes] = useState([]);
    const [active, setActive] = useState('fitlap');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        loadTemplate();
        setLoading(false);
    }, [active])


    const loadTemplate = () => {
        Api.get('/admin/menu-templates/' + params.id).then(response => {
            setTemplate(response.data.data);
            loadAvailableRecipes(response.data.data.intolerances + '-' + response.data.data.preference)
            console.log(response.data.data)
        })
    }

    const loadAvailableRecipes = (type) => {
        Api.get('/admin/menu-templates/available-recipes?type=' + type).then(response => {
            setRecipes(response.data);
            console.log(response.data)
        })
    }

    const onFinish = (values: any) => {

        Api.put("/admin/menu-templates/" + template.id, values).then(res => {
            setTemplate(res.data.data)
            return message.success('Template updated');
        }).catch(e => {
            return message.error('Template update failed');
        });
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/menu-templates">Menu Templates</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{template.id}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                {loading || !recipes || !template.id ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Form
                        name="menu-template"
                        labelCol={{span: 5}}
                        initialValues={{
                            breakfast_id: template?.breakfast_id?.id,
                            lunch_id: template?.lunch_id?.id,
                            dinner_id: template?.dinner_id?.id,
                            supper_id: template?.supper_id?.id,
                        }}
                        autoComplete="off"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Breakfast"
                            name="breakfast_id">
                            <Select options={recipes}></Select>
                        </Form.Item>
                        <Form.Item
                            label="Lunch"
                            name="lunch_id">
                            <Select options={recipes}></Select>

                        </Form.Item>
                        <Form.Item
                            label="Dinner"
                            name="dinner_id">
                            <Select options={recipes}></Select>
                        </Form.Item>
                        <Form.Item
                            label="Supper"
                            name="supper_id">
                            <Select options={recipes}></Select>
                        </Form.Item>

                        <Form.Item wrapperCol={{offset: 5, span: 16}}>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </Content>
    );
}

export default MenuTemplateView;