import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Form, Input, Layout, message, Popconfirm, Space, Table, Tabs, Tag} from 'antd';
import {Link} from "react-router-dom"
import Api from "../../api";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {redirect} from "../../helpers";

const {TabPane} = Tabs;
const {Content} = Layout;

const CustomUnitsView = ({onClickEvent}) => {
    const [customUnits, setCustomUnits] = useState([]);
    const [active, setActive] = useState('fitlap');

    useEffect(() => {
        loadCustomUnits();
    }, [active])


    const loadCustomUnits = () => {
        Api.get('/admin/custom-units').then(response => {
            setCustomUnits(response.data.data);
        })
    }


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (value, record) => <Space><span>{record.id}</span></Space>,
        },
        {
            title: 'Unit of measurement',
            dataIndex: 'plural',
            key: 'plural',
            render: (plural, record) => <Link to={"/ingredients/custom-units/" + record.id}>{record.singular} / {record.plural}</Link>,
            width: 200,
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <Link to={"/ingredients/custom-units/" + record.id}><EditOutlined/> edit</Link>
                </Space>,
            width: 30,
        },
    ];

    const onFinish = (values: any) => {
        let data = {"singular": values.singular, "plural": values.plural}
        Api.post("/admin/custom-units", data).then(res => {
            loadCustomUnits()
            return message.success('Custom unit added');
        }).catch(e => {
            return message.error('Custom unit adding failed');
        });
    };

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/ingredients">Ingredients</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/ingredients/custom-units">Custom Units</Link></Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <Form
                    name="new-allergen"
                    autoComplete="off"
                    onFinish={onFinish}
                >

                    <Form.Item
                        label="Singular"
                        name="singular"
                        required={true}>
                        <Input type={"text"}/>
                    </Form.Item>

                    <Form.Item
                        label="Plural"
                        name="plural"
                        required={true}>
                        <Input type={"text"}/>
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Add
                        </Button>
                    </Form.Item>
                </Form>
                <Table columns={columns} dataSource={customUnits} pagination={false}></Table>
            </div>
        </Content>
    );
}

export default CustomUnitsView;