import React, {useEffect, useState, useRef} from "react";
import {Layout, Breadcrumb, List, Popconfirm, Skeleton, Row, Col, Tabs, Avatar, Input, Button, message} from 'antd';
import Api from "../api.js";
import {
    DeleteOutlined,
    UserOutlined
} from '@ant-design/icons';
import dayjs from "dayjs";
import {Link} from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component";

const {TextArea} = Input;
const {TabPane} = Tabs;
const {Content} = Layout;

const Comments = () => {
    const [comments, setComments] = useState('');
    const [loading, setLoading] = useState(true);
    const [active, setActive] = useState('recipe');
    const [per_page, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [reply, setReply] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        loadComments();
    }, [active])

    const deleteComment = (comment) => {
        Api.delete('/admin/comments/' + comment.id).then(res => {
            loadComments();
        })
    }

    const loadComments = (nextPage) => {
        let params = `&per_page=${per_page}`

        if (nextPage) {
            params += `&page=${nextPage}`;
        }

        Api.get('/admin/comments?type=' + active + params).then(response => {
            setHasMore(response.data.next_page_url);
            setPage(response.data.current_page);
            setComments(nextPage ? [...comments, ...response.data.data] : response.data.data);

            setLoading(false);
        })
    };

    const changeTab = (value) => {
        setActive(value);
    }

    const createComment = () => {
        const value = inputRef.current.resizableTextArea.textArea.value;

        if (!value) {
            return message.error("Comment required")
        }

        setLoading(true);

        // Comment content
        const data = {
            body: value,
            commentable_type: reply.type,
            commentable_id: reply.commentable_id,
            parent_id: reply.id
        };

        Api.post("/v2/comment/store", data).then(res => {
            // Clear comment textarea
            setReply(false);

            loadComments();
        });
    };

    const renderCreateComment = () => (
        <Row className="create-comment">
            <TextArea placeholder={"Comment here"} rows={4} ref={inputRef}/>
            <Button className="main-btn" loading={loading} onClick={createComment} type="primary">Add comment</Button>
            <Button onClick={() => setReply(false)} className="main-btn">Cancel</Button>
        </Row>
    );

    const getCommentUrl = (comment) => {
        Api.get('/admin/comments/' + comment.id + '/url').then(response => {
            window.open(response.data.url, "_blank")
        })
    }


    const renderTabContent = (type) => {
        if (loading) {
            return <Skeleton avatar loading={true} active/>;
        }

        return (
            <InfiniteScroll
                dataLength={comments.length}
                next={() => loadComments(page + 1)}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{textAlign: 'center'}}>
                        <b>All data loaded!</b>
                    </p>
                }
            >
                <List
                    itemLayout="horizontal"
                    dataSource={comments}
                    renderItem={comment => {
                        if (!comment.user) {
                            return false;
                        }

                        return (
                            <List.Item
                                actions={
                                    [
                                        <>

                                            {
                                                (() => {
                                                    if (type === 'trainingvideo')
                                                        return <Link target={"_blank"} style={{marginRight: 10}}
                                                                     to={'/training-videos/' + comment.commentable_id}>#{comment.commentable_id}</Link>
                                                    if (type === 'blog' || type === 'testimonial')
                                                        return <Button type={"primary"} onClick={() => getCommentUrl(comment)}>#{comment.commentable_id}</Button>
                                                    else
                                                        return <Link target={"_blank"} style={{marginRight: 10}}
                                                                     to={'/' + type + '/' + comment.commentable_id}>#{comment.commentable_id}</Link>
                                                })()
                                            }
                                            {/*<Link target={"_blank"} style={{marginRight: 10}} to={'/' + type + '/' + comment.commentable_id}>#{comment.commentable_id}</Link>*/}
                                            <Popconfirm
                                                title="Are you sure you wanna delete this comment?"
                                                onConfirm={() => deleteComment(comment)}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <a key="delete-comment"
                                                   className="delete-comment"><DeleteOutlined/> delete</a>
                                            </Popconfirm>
                                        </>
                                    ]}>
                                <List.Item.Meta
                                    style={{alignItems: "center"}}
                                    avatar={<Avatar src={comment.user.avatar} icon={<UserOutlined/>}/>}
                                    title={<div><Link to={"/user/" + comment.user.user_id}>{comment.user.name_f}</Link> <span
                                            style={{
                                                fontWeight: 400,
                                                fontSize: 12,
                                                color: "grey"
                                            }}>{dayjs(comment.created_at).format('DD.MM.YYYY HH:mm')}</span></div>}
                                    description={<div>
                                        {comment.body}
                                        <Row>
                                            {(reply && reply.id === comment.id) ? renderCreateComment() : (
                                                <Button className="action-btn" onClick={() => setReply({
                                                    ...comment,
                                                    type: type
                                                })} type="default" size="small">Reply</Button>
                                            )}
                                        </Row>
                                    </div>
                                    }
                                />
                            </List.Item>
                        )
                    }}
                />
            </InfiniteScroll>
        )
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Comments</Breadcrumb.Item>
            </Breadcrumb>

            <Row>
                <Col span={24}>
                    <div className="site-layout-card">
                        <h1>Comments</h1>

                        <Tabs defaultActiveKey="recipe" onChange={changeTab}>
                            <TabPane tab={"Recipe"} key="recipe">
                                {renderTabContent("recipe")}
                            </TabPane>
                            <TabPane tab="Testimonial" key="testimonial">
                                {renderTabContent("testimonial")}
                            </TabPane>
                            <TabPane tab="Blog" key="post">
                                {renderTabContent("blog")}
                            </TabPane>
                            <TabPane tab="Training" key="trainingvideo">
                                {renderTabContent("trainingvideo")}
                            </TabPane>
                        </Tabs>
                    </div>
                </Col>
            </Row>
        </Content>
    );
}

export default Comments;