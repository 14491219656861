import React, { useEffect, useState } from "react";
import {
    CartesianGrid,
    ResponsiveContainer,
    Tooltip as ChartTooltip,
    XAxis,
    YAxis,
    Bar,
    BarChart,
    LabelList
} from "recharts";
import {Button, DatePicker, Row, Spin} from "antd";
import Api from "../../api";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import {Link} from "react-router-dom";

const { RangePicker } = DatePicker;

const ErrorLogChart = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [range, setRange] = useState({ startDate: moment().startOf('day').format('YYYY-MM-DD'), endDate: moment().endOf('day').format('YYYY-MM-DD') });

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () => {
        setLoading(true);

        Api.get('/admin/error-log').then(res => {
            processLogData(res.data.log);
            //setData(res.data);
            setLoading(false);
        });
    };

    const processLogData = (logEntries) => {
        // Filter for production.ERROR logs
        const errorLogs = logEntries.filter(entry => entry.level === 'production.ERROR');

        // Aggregate errors by hour
        const errorCounts = errorLogs.reduce((acc, log) => {
            const hour = moment(log.timestamp).format('YYYY-MM-DD HH:00:00');
            if (!acc[hour]) {
                acc[hour] = 0;
            }
            acc[hour]++;
            return acc;
        }, {});

        // Generate a list of hours in the range
        const hoursInRange = [];
        let currentHour = moment(range.startDate).startOf('day');
        const endHour = moment(range.endDate).endOf('day');

        while (currentHour <= endHour) {
            hoursInRange.push(currentHour.format('YYYY-MM-DD HH:00:00'));
            currentHour.add(1, 'hour');
        }

        // Prepare the chart data, including hours with zero errors
        const chartData = hoursInRange.map(hour => ({
            hour,
            errors: errorCounts[hour] || 0
        }));

        setData(chartData);
    };

    const handleRangeChange = (dates, dateStrings) => {
        if (dates) {
            const startDateString = dateStrings[0];
            const endDateString = dateStrings[1];
            loadData(startDateString, endDateString);
        } else {
            loadData();
        }
    };

    return (
        <>
            <Row type="flex" justify="space-between" align="middle">
                <h1>Daily errors</h1>

                <Link to="/error-log">
                    <Button>Error log</Button>
                </Link>
            </Row>

            {loading && (
                <Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />} className="spinner-loader" />
            )}

            <ResponsiveContainer width={"100%"} height={280}>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />

                    <XAxis dataKey="hour" tickFormatter={(tick) => moment(tick).format("HH:mm")} />

                    <YAxis domain={[0, 50]}/>

                    <ChartTooltip />

                    {/* Render bars only for hours with errors */}
                    <Bar dataKey="errors" fill="#ff4d4f" barSize={3} fontSize={12} radius={12}>
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </>
    );
};

export default ErrorLogChart;