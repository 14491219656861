import React, {useState} from "react";
import {Layout, Breadcrumb, List, Button, message, Space, Table, Row, Alert} from 'antd';
import {Link} from "react-router-dom"
import eeIcon from "../assets/icons/et.png";
import enIcon from "../assets/icons/en.png";
import fiIcon from "../assets/icons/fi.png";
import axios from "axios";
import {Header} from "antd/es/layout/layout";

const {Content} = Layout;

const Translations = () => {

    const data = [
        {name: "Estonian", lang: "et", icon: eeIcon},
        {name: "English", lang: "en", icon: enIcon},
        {name: "Finnish", lang: "fi", icon: fiIcon},
    ];

    const syncTranslations = () => {
        const apiDomains = ['https://api.fitlap.ee', 'https://api.fitlap.com', 'https://api.fitlap.in', 'http://127.0.0.1:8000', 'https://api2.fitlap.fi'];
        apiDomains.map(domain => {
            axios({
                method: "put",
                url: "/admin/translations/sync",
                baseURL: domain,
                data: [],
                headers: {
                    'X-Auth-Token': `4sC6KWJisLkOkFQCW5xU`
                },
            }).then(res => {
                message.success('Translations synced: ' + domain);
            })
        })
    }

    return (
        <Content style={{margin: '0 16px'}}>

            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Translations</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-card">
                <div>
                    <Space>
                        <h1>Languages</h1>
                        <Button onClick={syncTranslations} type="primary">Sync</Button>
                    </Space>
                </div>

                <List
                    size="large"
                    dataSource={data}
                    renderItem={item => {
                        return (
                            <List.Item>
                                <Link to={item.lang}><img style={{width: 20}} src={item.icon} alt=""/> {item.name} <span>({item.lang})</span></Link>
                            </List.Item>
                        )
                    }}
                />
            </div>
        </Content>
    );
}

export default Translations;