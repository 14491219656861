import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Input, Layout, message, Modal, Popconfirm, Row, Space, Table, Tabs, Tag} from 'antd';
import {Link, useNavigate} from "react-router-dom"
import Api from "../api";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {Form, notification } from 'antd';

const {TabPane} = Tabs;
const {Content} = Layout;

const SubstituteGroupsView = ({onClickEvent}) => {
    const [groups, setGroups] = useState([]);
    const [active, setActive] = useState('fitlap');
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();
    const [form] = Form.useForm(); // Create a form instance

    useEffect(() => {
        loadGroups();
    }, [active])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'value',
            key: 'value',
            width: '5%',
            render: (value, record) => <Space><span>{record.value}</span></Space>,
        },
        {
            title: 'Name',
            dataIndex: 'label',
            key: 'label',
            render: (label, record) => {
                return (
                    label
                )
            },
            width: 200,
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <Link to={"/ingredients/substitute-groups/" + record.value}><EditOutlined/> edit</Link>
                </Space>,
            width: 30,
        },
    ];

    const loadGroups = () => {
        Api.get('/admin/substitute-groups').then(response => {
            setGroups(response.data.data);
            setLoading(false);
        })
    }

    // Handle form submission
    const handleFinish = async (values) => {
        try {
            // Make the API request to create a new substitute group
            await Api.post('/admin/substitute-groups', values);

            // Notify success
            notification.success({
                message: 'Success',
                description: 'Substitute group added successfully.',
            });

            // Close the modal and reload the groups
            setIsModalVisible(false);
            loadGroups(); // Refresh the list of groups
            form.resetFields(); // Reset the form fields
        } catch (error) {
            // Notify error
            notification.error({
                message: 'Error',
                description: 'Failed to add substitute group.',
            });
        }
    };

    const renderTabContent = (type) => {
        return (
            <Table loading={loading} pagination={false} style={{cursor: "pointer"}} columns={columns} dataSource={groups}/>
        )
    }

    const changeTab = (value) => {
        setActive(value);
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Ingredients</Breadcrumb.Item>
                <Breadcrumb.Item>Substitute Groups</Breadcrumb.Item>
            </Breadcrumb>

            <AddSubstituteGroupModal
                isModalVisible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                loadGroups={loadGroups}
            />

            <div className="site-layout-card">
                <Row type="flex" justify="end">
                    <Button type="primary" onClick={() => setIsModalVisible(true)}>Add new substitute group</Button>
                </Row>

                <Tabs defaultActiveKey="1" onChange={changeTab}>
                    <TabPane tab="Substitute groups" key="substitute-groups">
                        {renderTabContent('substitute-groups')}
                    </TabPane>
                </Tabs>
            </div>
        </Content>
    );
}


const AddSubstituteGroupModal = ({ isModalVisible, setIsModalVisible, loadGroups }) => {
    const [form] = Form.useForm(); // Create a form instance

    // Handle form submission
    const handleFinish = async (values) => {
        try {
            // Make the API request to create a new substitute group
            await Api.post('/admin/substitute-groups', values);

            // Notify success
            notification.success({
                message: 'Success',
                description: 'Substitute group added successfully.',
            });

            // Close the modal and reload the groups
            setIsModalVisible(false);
            loadGroups(); // Refresh the list of groups
            form.resetFields(); // Reset the form fields
        } catch (error) {
            // Notify error
            notification.error({
                message: 'Error',
                description: 'Failed to add substitute group.',
            });
        }
    };

    return (
        <Modal
            title="Add Substitute Group"
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
        >
            <Form
                form={form}
                onFinish={handleFinish}
                layout="vertical"
            >
                <Form.Item
                    name="name"
                    label="Group Name"
                    rules={[{ required: true, message: 'Please input the group name!' }]}
                >
                    <Input placeholder="Enter the name of the group" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Add Group
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default SubstituteGroupsView;