import React, {useEffect, useState} from "react";
import {
    Form,
    Input,
    Button,
    Modal,
    message,
    InputNumber,
    Switch, Collapse, Space, Select, Alert, Checkbox, Col, Row
} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import Api from "../api";
import config from '../config/config'
import TextArea from "antd/es/input/TextArea";
import {redirect} from "../helpers";

const NewIngredient = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { Panel } = Collapse;
    const [categories, setCategories] = useState(false);
    const [subGroups, setSubGroups] = useState(false);
    const [customUnits, setCustomUnits] = useState([]);
    const showModal = () => {
        setIsModalVisible(true);
    };

    useEffect(() => {
        getFunctions();
    })

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getFunctions = () => {
        console.log(config)
        if(categories === false) {
            Api.get('/admin/functions').then(response => {
                setCategories(response.data.categories);
                setSubGroups(response.data.substituteGroups);
                handleCustomUnits(response.data.customUnits)
            })
        }
    }

    const intolerances = [
        { label: "Sisaldab gluteeni", value: config.intolerances.gluten_free},
        { label: "Sisaldab laktoosi", value: config.intolerances.lactose_free},
        { label: "Taimetoit", value: config.intolerances.vegeterian},
        { label: "Vegan", value: config.intolerances.vegan},
    ];
    const intolerance_alternatives = [
        { label: "Gluteenivaba alternatiiv", value: config.intolerances.gluten_free},
        { label: "Laktoosivaba alternatiiv", value: config.intolerances.lactose_free},
    ];

    const handleCustomUnits = (customUnits) => {
        const formatted = [];
        customUnits.forEach((el) => {
            setCustomUnits(current => [...current, {'value': el.id,'label': el.singular}])
        })
    }

    const getCategories = () => {
        if(categories === false) {
            Api.get('/admin/functions/ingredients/categories').then(response => {
                setCategories(response.data);
                console.log(response.data)
            })
        }
        return categories;
    }

    const getSubsitudeGroups = () => {
        if(subGroups === false) {
            Api.get('/admin/functions/ingredients/sub-groups').then(response => {
                setSubGroups(response.data);
                console.log(response.data)
            })
        }
        return subGroups;
    }

    const RenderForm = () => {
        const onFinish = (values: any) => {

            values.price = values.price ? values.price : 0;
            values.price = values.price / 10;
            values.order_url = values.order_url !== "" ? values.order_url : "";

            Api.post("/admin/ingredients", values).then(res => {
                setIsModalVisible(false);
                console.log('Response:', res.data);
                // redirect('/ingredient/' + res.data.id)
            });
        };

        const onChange = (e) => {

        }

        const checkBarcode = (e) => {
            const barcode = e.target.value;
            if(barcode.length >= 8) {
                Api.get('/admin/barcodes/' + barcode ).then(response => {
                    if(response.data === 1) {
                        return message.error('Failed: Barcode already exists!');
                    }
                })
            }
        }

        const onFinishFailed = (errorInfo: any) => {
            return message.error('Failed: please fix the shown errors');
        };

        return (
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >


                <Alert message="Allergeenid ja Nutridata saab sisestada peale toiduaine loomist!" type="info" />
                <br/>
                <Form.Item
                    label="Avalik nimi"
                    name="original_name"
                    rules={[{ required: true, message: 'Palun sisesta toote avalik nimi' }]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Osastavas käändes nimi"
                    name="partitive_name">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Privaatne nimi"
                    name="private_name">
                    <Input />
                </Form.Item>


                <Form.Item label="Kategooria" name="ingredient_category_id"
                           rules={[{ required: true, message: 'Palun vali toote kategooria' }]}>
                    <Select options={categories} defaultValue={'-- Vali --'}>
                    </Select>
                </Form.Item>

                <Form.Item label="Asenduste grupp" name="substitute_group">
                    <Select options={subGroups} defaultValue={'-- Vali --'}>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Energia (kcal)"
                    name="energy"
                >
                    <InputNumber
                        defaultValue="0"
                        min="0"
                        step="0.01"
                        stringMode
                    />
                </Form.Item>

                <Form.Item
                    label="Süsivesikud (g)"
                    name="carbohydrate">
                    <InputNumber
                        defaultValue="0"
                        min="0"
                        step="0.01"
                        stringMode
                    />
                </Form.Item>

                <Form.Item
                    label="Valgud (g)"
                    name="protein">

                    <InputNumber
                        defaultValue="0"
                        min="0"
                        step="0.01"
                        stringMode
                    />
                </Form.Item>

                <Form.Item
                    label="Rasvad (g)"
                    name="fat">
                    <InputNumber
                        defaultValue="0"
                        min="0"
                        step="0.01"
                        stringMode
                    />
                </Form.Item>

                <Form.Item
                    label="Hind (kg)"
                    name="price">
                    <InputNumber
                        defaultValue="0"
                        min="0"
                        step="0.01"
                        stringMode
                    />
                </Form.Item>

                <Form.Item label="Hinna klass" nam="price_range">
                    <Select defaultValue={'-- Vali --'}>
                        <Select.Option value="0">-- Vali --</Select.Option>
                        <Select.Option value="1">Odav</Select.Option>
                        <Select.Option value="2">Keskmine</Select.Option>
                        <Select.Option value="3">Kallis</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Minimaalne kogus (g)"
                    name="min_amount"
                >
                    <InputNumber
                        defaultValue="0"
                        min="0"
                        step="0.01"
                        stringMode
                    />
                </Form.Item>

                <Form.Item label="Aktiivne" name="active">
                    <Switch />
                </Form.Item>


                <hr/>

                <Form.Item
                    label="Valmis koguse kordaja"
                    name="cooked_amount_factor"
                >
                    <InputNumber
                        defaultValue="0"
                        min="0"
                        step="0.01"
                        stringMode
                    />
                </Form.Item>

                <Form.Item
                    label="Ostulink (URL)"
                    name="order_url">
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Logo (URL)"
                    name="logo_image">
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Logo pilt (URL) (~50 x 30 px)"
                    name="logo_landing_page">
                    <Input />
                </Form.Item>

                <Form.Item label="Vabavara" name="no_limit" valuePropName="checked">
                            <Checkbox value="1"></Checkbox>
                </Form.Item>
                <Form.Item label="Muna" name="is_egg" valuePropName="checked">
                            <Checkbox value="1"></Checkbox>
                </Form.Item>



                <hr />

                <Form.Item
                    label="Märkmed"
                    name="notes">
                    <TextArea />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Salvesta
                    </Button>
                </Form.Item>
            </Form>
        );
    };


    return (
        <>

            <Space>
                <Button className="add-ingredient-btn" type="primary" onClick={showModal}>
                    <PlusOutlined/> Add new ingredient
                </Button>
            </Space>


            <Modal title="Uus Toiduaine"
                   visible={isModalVisible}
                   onCancel={handleCancel}
                   footer={null}
                   width={1000}
            >
                <RenderForm/>
            </Modal>
        </>

    );
};

export default NewIngredient;