import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Skeleton,
    Row,
    Table,
    Space,
    Button,
} from 'antd';
import {useParams, Link} from 'react-router-dom';
import Api from "../api";
import moment from "moment";
import {PlusOutlined} from "@ant-design/icons";

const {Content} = Layout;

const Tasks = () => {
    const params = useParams();
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadTasks()
    }, [])


    const loadTasks = (nextPage) => {
        setLoading(true);
        Api.get('/admin/tasks').then(response => {
            setTasks(response.data)
            setLoading(false);
        })
    };

    const generateMissing = () => {
        Api.get('/admin/tasks/generate-default').then(response => {
            setTasks(response.data)
        })
    }

    const columns = [
        {
            title: 'Day',
            dataIndex: 'date',
            render: (text, record) =>
                moment().isSame(record.date, 'day') ? "Today" : moment(record.date).format('DD-MM-YYYY'),
            width: '10%',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            width: '15%',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            render: (text, record) => <div dangerouslySetInnerHTML={{__html: text}}/>
        },
        {
            title: 'Users Opened',
            dataIndex: 'participants_count',
            width: '15%',
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <Link to={"/task/" + record.id}>edit</Link>
                </Space>,
            width: 30,
        },
    ];

    return (

        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Tasks</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">

                <h1>Tasks</h1>

                <Space direction="vertical">
                    <Row>
                        <Button className="add-ingredient-btn" type="primary" onClick={generateMissing}>
                            <PlusOutlined/> Generate missing/new
                        </Button>
                    </Row>

                    <Table style={{width: "100%"}} loading={loading} columns={columns} dataSource={tasks} pagination={false}
                           rowClassName={(record, index) => moment().isSame(record.date, 'day') ? 'task-today' : 'normal'}/>

                </Space>
            </div>
        </Content>
    );
};


export default Tasks;