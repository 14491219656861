import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Row,
    Col,
    Input,
    Button,
    Skeleton,
    List,
    Upload,
    Form,
    Image,
    Space,
    Divider,
    Select, InputNumber, Checkbox, Table, Typography, message, Alert, Popconfirm
} from 'antd';
import {DeleteOutlined, UploadOutlined} from "@ant-design/icons";
import {useParams, Link} from 'react-router-dom';
import TextArea from "antd/es/input/TextArea";
import Api from "../api";
import config from "../config/config";
import ReactQuill from "react-quill";
import Search from "antd/lib/input/Search";
import {set, setIn} from "immutable";
import {redirect} from "../helpers";
import IngredientList from "../components/ingredient-list";
import IngredientSearch from "../components/ingredient-search";

const {Content} = Layout;

const CreateRecipeView = () => {
    const params = useParams();
    const [recipe, setRecipe] = useState('');
    const [categories, setCategories] = useState(false);
    const [mealTypes, setMealTypes] = useState('');
    const [search, setSearch] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        loadCategories()
    }, [])

    const loadCategories = () => {
        if (categories === false) {
            Api.get('/admin/recipes/functions/categories').then(res => {
                setCategories(res.data)
            })
        }
    }

    const hasBitwise = (values, value) => {
        if (values === 0) {
            return false
        }
        return ((values & value) === value);
    }

    const getMealTypes = (test) => {
        const types = [];


        if (hasBitwise(test.meal_type, config.mealTypes.breakfast)) {
            types.push(config.mealTypes.breakfast)
        }
        if (hasBitwise(test.meal_type, config.mealTypes.lunch)) {
            types.push(config.mealTypes.lunch)
        }
        if (hasBitwise(test.meal_type, config.mealTypes.dinner)) {
            types.push(config.mealTypes.dinner)
        }
        if (hasBitwise(test.meal_type, config.mealTypes.supper)) {
            types.push(config.mealTypes.supper)
        }
        console.log("meal types:" + types)

        return types
    }

    const calculateMealType = (recipe) => {
        let value = 0;

        if (recipe.breakfast) {
            value += config.mealTypes.breakfast;
        }

        if (recipe.lunch_and_dinner) {
            value += config.mealTypes.lunch;
            value += config.mealTypes.dinner;
        }
        if (recipe.supper) {
            value += config.mealTypes.supper;
        }

        return value;
    }

    const modules = {
            toolbar: [
                [{'header': [1, 2, false]}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link'],
                ['clean'],
                ['emoji']
            ],
            "emoji-toolbar": true,
        },
        formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link',
            'emoji'
        ]

    const onSearch = (value) => {

        if(value.length > 2) {
            Api.post('admin/ingredients/search', {key: value}).then(res => {
                setSearch(res.data)
            })
        } else {
            setSearch([])
        }
    }

    const uploadImage = (value) => {
        console.log(value);

        const formData = new FormData();

        // Update the formData object
        formData.append(
            "image", value.file.originFileObj
        );

        if (value.file.status !== 'uploading') {
            Api.post('admin/recipes/' + recipe.id + '/image', formData).then(res => {
                const updatedRecipe = setIn(recipe, ['image'], res.data.data.thumbnail + "?date=" + Date.now())
                setRecipe(updatedRecipe)
                value.fileList = []
                return message.success(`${value.file.name} file uploaded successfully`);
            }).catch(e => {
                return message.error(`${value.file.name} file upload failed.`);
            });
        }

    }

    const setIngredient = (ingredient) => {
        ingredient.pivot_energy = 0;
        ingredient.amount = 0;
        setIngredients(current => [...current, ingredient])
        setSearch([])
        setSearchValue('')
    }


    const forVeganOnlyOptions = [
        {label: "-- Vali --", value: 0},
        {label: "1 - Sobib ka Veganile", value: 1},
        {label: "2 - Ainult Veganile", value: 2},
    ]

    function handleIsSimpleChange(event) {
        const updatedData = setIn(recipe, ['is_simple'], event.target.checked)
        setRecipe(updatedData)
    }

    const onFinish = (values: any) => {
        values.ingredients = {...ingredients};
        values.meal_type = calculateMealType(values)
        console.log('Success:', values);

        Api.post('admin/recipes', values).then(res => {
            console.log(res)
            message.success(`Retsept edukalt lisatud!`);
            redirect('/recipe/' + res.data.id)
        }).catch(e => {
            return message.error(`${e.message}`);
        });
    };


    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/recipes">Recipes</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">

                <h1>{recipe.original_name}</h1>

                <div>
                    <Alert style={{margin: '10px 0px'}}
                           message="Pilti saab lisada pärast retsepti salvestamist."
                           type="info"
                           showIcon={true}
                           closable
                    />
                    <Form
                        name="create-recipe"
                        labelCol={{span: 5}}
                        // wrapperCol={{span: 16}}
                        initialValues={{}}
                        autoComplete="off"
                        onFinish={onFinish}
                    >
                        <Divider/>

                        <Form.Item
                            label="Retsepti nimi"
                            name="original_name"
                            rules={[{required: true, message: 'Palun sisesta retsepti avalik nimi'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="SEO Title"
                            name="seo_title">
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="SEO Description"
                            name="seo_description">
                            <TextArea/>
                        </Form.Item>

                        <Form.Item label="Kategooria" name="recipe_category_id"
                                   rules={[{required: true, message: 'Palun vali retsepti kategooria'}]}>
                            <Select options={categories} value={recipe.recipe_category_id}></Select>
                        </Form.Item>

                        <Form.Item
                            label="Valmistamise aeg (min)"
                            name="preparation_time">
                            <InputNumber min={0} controls={true}/>
                        </Form.Item>

                        <Divider/>


                        <Form.Item label="Lihtne" name="is_simple" valuePropName="checked"><Checkbox
                            value="1" onChange={handleIsSimpleChange}></Checkbox></Form.Item>

                        <Form.Item label="Aktiivne" name="is_active" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Avalik" name="is_public" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Esiletõstetud" name="is_featured" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Basic" name="is_basic" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Otse poest" name="is_from_store" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Valmistoit toidukohas" name="is_restaurant_meal"
                                   valuePropName="checked"><Checkbox value="1"></Checkbox></Form.Item>


                        <Divider/>

                        <Form.Item label="Hommikusöök" name="breakfast" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Lõunasöök / õhtusöök" name="lunch_and_dinner"
                                   valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Hiline õhtusöök" name="supper" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Divider/>
                        <Form.Item label="Gluteenivaba" name="is_gluten_free" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Laktoosivaba" name="is_lactose_free" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Taimetoit" name="is_vegetarian_food" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Pescatarian" name="is_pescatarian_food" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Vegan" name="for_vegan_only">
                            <Select options={forVeganOnlyOptions} value={0}></Select>
                        </Form.Item>

                        <Divider/>

                        <Form.Item label="Kirjeldus" name="instructions">
                            <ReactQuill modules={modules} formats={formats}/>
                        </Form.Item>


                        <Form.Item label="Toiduained" name="ingredients">
                            <Space>
                                <Alert message="Toiduained peaksid kokku olema 375 kcal." type="info" showIcon/>
                                <Alert message="Maitseaineteks loetakse toiduaineid, mille kogus on 0/NaN." type="info"
                                       showIcon/>
                            </Space>

                            <IngredientList setIngredients={setIngredients} ingredients={ingredients}/>
                        </Form.Item>

                        <Form.Item label="Otsi toiduainet">
                            <IngredientSearch searchResponse={search} setSearchResponse={setSearch} onClickEvent={setIngredient} onlyId={false}/>
                        </Form.Item>

                        <Form.Item label="Märkmed (nähtav vaid adminis)" name="notes">
                            <TextArea/>
                        </Form.Item>
                        <Form.Item label="Keywords(default + extra)" name="keywords">
                            <TextArea/>
                        </Form.Item>
                        <Form.Item label="Extra keywords" name="extra_keywords">
                            <TextArea/>
                        </Form.Item>


                        <Form.Item wrapperCol={{offset: 5, span: 16}}>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Salvesta
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
                )
            </div>
        </Content>
    )
        ;
}

export default CreateRecipeView;