import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Row,
    Col,
    Input,
    Button,
    Skeleton,
    Form,
    InputNumber,
    Table,
    Switch,
    Collapse,
    message,
    Space,
    Popconfirm,
    Tag,
    Typography,
    Menu, Alert
} from 'antd';
import {useParams, Link} from 'react-router-dom';
import Api from "../api";
import {DeleteOutlined, CheckCircleOutlined, FileSearchOutlined, CheckOutlined} from "@ant-design/icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const {Content} = Layout;

const AdventCalendar = () => {
    const params = useParams();
    const [adventCalendar, setAdventCalendar] = useState([]);
    const [changes, setChanges] = useState([]);
    const [loaded, setLoaded] = useState(false);



    useEffect(() => {
        loadSettings();
    }, [])

    const loadSettings = () => {
        Api.get('/admin/advent-calendar').then(response => {
            console.log(response.data);
            setAdventCalendar(response.data);
        })
    }

    const columns = [
        {
            title: 'Day',
            dataIndex: 'date',
            width: '10%',
        },
        {
            title: 'Gift',
            dataIndex: 'gift',
            render: (text, record) => <div dangerouslySetInnerHTML={{__html: text}}/>
        },
        {
            title: 'Gift Type',
            dataIndex: 'gift_type',
            width: '5%',
        },
        {
            title: 'Points',
            dataIndex: 'points',
            render: (text, record) =>
                record.gift_type === "points" ? record.points : false,
            width: '5%',
        },
        {
            title: 'Users Opened',
            dataIndex: 'users_count',
            width: '5%',
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <Link to={"/advent-calendar/" + record.id}>edit</Link>
                </Space>,
            width: 30,
        },
    ];

    return (

        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Advent Calendar</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">

                <h1>Advent Calendar</h1>

                {!adventCalendar ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Table columns={columns} dataSource={adventCalendar} pagination={false}/>
                )}
            </div>
        </Content>
    );
};


export default AdventCalendar;