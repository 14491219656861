import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Form, Input, Layout, message, Popconfirm, Space, Table, Tabs, Tag} from 'antd';
import {Link} from "react-router-dom"
import Api from "../../api";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {redirect} from "../../helpers";

const {TabPane} = Tabs;
const {Content} = Layout;

const AllergensView = ({onClickEvent}) => {
    const [allergens, setAllergens] = useState([]);
    const [active, setActive] = useState('fitlap');

    useEffect(() => {
        loadAllergens();
    }, [active])



    const loadAllergens = () => {
        Api.get('/admin/allergens').then(response => {
            setAllergens(response.data.data);
        })
    }


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '5%',
            render: (value, record) => <Space><span>{record.id}</span></Space>,
        },
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
            render: (title, record) => <Link to={"/ingredients/allergens/" + record.id}>{title}</Link>,
            width: 200,
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <Link to={"/ingredients/allergens/" + record.id}><EditOutlined/> edit</Link>
                </Space>,
            width: 30,
        },
    ];

    const onFinish = (values: any) => {
        let data = {"title": values.title}
        Api.post("/admin/allergens", data).then(res => {
            loadAllergens()
            return message.success('Allergen added');
        }).catch(e => {
            return message.error('Allergen adding failed');
        });
    };

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Ingredients</Breadcrumb.Item>
                <Breadcrumb.Item>Substitute Groups</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <Form
                    name="new-allergen"
                    autoComplete="off"
                    onFinish={onFinish}
                >

                    <Form.Item
                        label="Name"
                        name="title"
                        required={true}>
                        <Input type={"text"}/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Add
                        </Button>
                    </Form.Item>
                </Form>
                <Table columns={columns} dataSource={allergens} pagination={false}></Table>
            </div>
        </Content>
    );
}

export default AllergensView;