import React, {useEffect, useState, memo} from "react";
import {Layout, Breadcrumb, Row, Col, Input, Button, Skeleton, message, Select} from 'antd';
import {useParams, Link} from 'react-router-dom';
import Api from "../api";
import eeIcon from "../assets/icons/et.png";
import enIcon from "../assets/icons/en.png";
import lvIcon from "../assets/icons/lv.png";
import ruIcon from "../assets/icons/en.png";
import {CheckCircleOutlined, DeleteOutlined, DeleteColumnOutlined} from '@ant-design/icons';
import axios from "axios";
import NewTranslation from "../components/new-translation";

const {Content} = Layout;
const {Search} = Input;
const {Option} = Select;

const TranslationView = () => {
    const [translations, setTranslations] = useState('');
    const [masterTranslation, setMasterTranslation] = useState('');
    const [loading, setLoading] = useState(true);
    const [keyword, setKeyword] = useState('');
    const [updated, setUpdated] = useState([]);
    const [filter, setFilter] = useState('all');
    const params = useParams();

    useEffect(() => {
        Api.get('/admin/translations?language=' + params.id).then(res => {
            if (res.data.data.length > 0) {
                setTranslations(res.data.data[0].translations);
            }

            if(params.id !== "et") {
                Api.get('/admin/translations?language=et').then(res => {
                    setMasterTranslation(res.data.data[0].translations)
                })
            }

            setLoading(false);
        })
    }, [])

    const saveTranslations = () => {
        let data = {
            language: params.id,
            translations: translations
        }

        const apiDomains = ['https://api.fitlap.ee', 'https://api.fitlap.com', 'https://api.fitlap.in', 'https://api2.fitlap.fi', 'http://127.0.0.1:8000'];

        apiDomains.map(domain => {
            axios({
                method: "post",
                url: "/admin/translations",
                baseURL: domain,
                data: data,
                headers: {
                    'X-Auth-Token': `4sC6KWJisLkOkFQCW5xU`
                },
            }).then(res => {
                message.success('Translations synced: ' + domain);
            })
        })
    }

    const removeTranslation = (key) => {
        const apiDomains = ['https://api.fitlap.ee', 'https://api.fitlap.com', 'https://api.fitlap.in', 'http://127.0.0.1:8000'];

        apiDomains.map(domain => {
            axios({
                method: "delete",
                url: "/admin/translations",
                baseURL: domain,
                data: {
                    key: key,
                    language: params.id
                },
                headers: {
                    'X-Auth-Token': `4sC6KWJisLkOkFQCW5xU`
                },
            }).then(res => {
                message.success('Translations deleted: ' + domain);
                // console.log(res.data)
                setTranslations(res.data.data.translations);
            })
        })
    }

    const updateTranslation = (key, value) => {
        // Save key to array to display that translation is changed
        setUpdated([...updated, key])

        // Clone translations object
        const updatedTranslations = Object.assign({}, translations);

        // Override translation
        updatedTranslations[key] = value;

        setTranslations(updatedTranslations);
    }

    const onSearch = (value) => {
        setKeyword(value);
    }

    const filterByValue = (array, string = '') => {
        return array.filter(o => Object.keys(o).some(k => {
            let keywordMatch = false;
            let filterMatch = true;

            // Filter by translated or not
            if (filter === 'empty' && o[1]) {
                filterMatch = false;
            }

            // Filter by keyword
            if (o[k]) {
                keywordMatch = o[k].toLowerCase().includes(string.toLowerCase())
            }

            return keywordMatch && filterMatch;
        }));
    }

    const asArray = Object.entries(translations);

    const isEdited = (key) => {
        return updated.includes(key);
    }

    const getImg = () => {
        if (params.id === 'et') {
            return eeIcon;
        }

        if (params.id === 'lv') {
            return lvIcon;
        }

        if (params.id === 'en') {
            return enIcon;
        }

        return "";
    };

    const changeFilter = (value) => {
        setFilter(value);
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/translations">Translations</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <h1><img style={{height: 30}} src={getImg()} alt=""/> Translations</h1>

                <Row type="flex" justify="end">
                    <NewTranslation setKeyword={setKeyword} updateTranslation={updateTranslation}/>

                    <Select defaultValue="all" style={{ width: 150, marginRight: 10 }} onChange={changeFilter}>
                        <Option value="all">Show all</Option>
                        <Option value="empty">Untranslated</Option>
                    </Select>

                    <Search placeholder="search" onSearch={onSearch} style={{width: 200, marginRight: 10}}/>
                    <Button style={{float: "right"}} onClick={saveTranslations} type="primary">Save</Button>
                </Row>

                <br/>

                {!loading && filterByValue(asArray, keyword).map(res => {
                    let translationKey = res[0];
                    let value = res[1];
                    let edited = isEdited(translationKey);

                    return (
                        <Row key={translationKey} className={"translation-row"}>
                            {params.id === "et" &&
                                <Col span={24} md={1}>
                                    <Button size={"small"} danger style={{marginRight: "1em"}} onClick={(e) => removeTranslation(translationKey)}>Delete</Button>
                                </Col>
                            }

                            <Col span={24} md={4}>
                                {translationKey}
                            </Col>
                            <Col span={24} md={9}>
                                {edited && (
                                    <CheckCircleOutlined />
                                )}
                                <Input key={value} onBlur={(e) => updateTranslation(translationKey, e.target.value)} defaultValue={value}/>

                            </Col>
                            {params.id !== "et" &&
                                <Col span={24} md={9} style={{marginLeft: "1em"}}>
                                    <Input key={masterTranslation[translationKey]} disabled defaultValue={masterTranslation[translationKey]}/>
                                </Col>
                            }

                        </Row>
                    )
                })}

                {loading && (
                    <Skeleton title={true} loading={true} active/>
                )}
            </div>
        </Content>
    );
}

export default TranslationView;