import React, {useEffect, useState} from "react";
import {Breadcrumb, Input, Layout, message, Popconfirm, Skeleton, Space, Table, Tabs, Tag} from 'antd';
import {Link} from "react-router-dom"
import Api from "../api";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {redirect} from "../helpers";

const {TabPane} = Tabs;
const {Search} = Input;
const {Content} = Layout;

const MenuTemplatesView = ({onClickEvent}) => {
    const [templates, setTemplates] = useState([]);
    const [active, setActive] = useState('fitlap');

    useEffect(() => {
        loadTemplates();
    }, [active])

    const columns = [
        {
            title: 'Day',
            dataIndex: 'day',
            key: 'day',
            width: '8%',
            render: (value, record) => "Day " + record.day,
        },
        {
            title: 'Breakfast',
            dataIndex: 'breakfast',
            key: 'breakfast',
            render: (label, record) => record.breakfast?.original_name,
            width: 200,
        },
        {
            title: 'Lunch',
            dataIndex: 'lunch',
            key: 'lunch',
            render: (label, record) => record.lunch?.original_name,
            width: 200,
        },
        {
            title: 'Dinner',
            dataIndex: 'dinner',
            key: 'dinner',
            render: (label, record) => record.dinner?.original_name,
            width: 200,
        },
        {
            title: 'Supper',
            dataIndex: 'supper',
            key: 'supper',
            render: (label, record) => record.supper?.original_name,
            width: 200,
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <Link to={"/menu-templates/" + record.id}><EditOutlined/> edit</Link>
                </Space>,
            width: 30,
        },
    ];

    const loadTemplates = () => {
        Api.get('/admin/menu-templates').then(response => {
            setTemplates(response.data);
            console.log(response.data)
        })
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Menu Templates</Breadcrumb.Item>
            </Breadcrumb>

                {!templates ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (

                    <>
                    <div className="site-layout-card">
                        <h2>Tavaline</h2>
                        <Table columns={columns} dataSource={templates['0-0']} pagination={false}></Table>
                    </div>
                    <div className="site-layout-card">
                        <h2>Gluteenivaba</h2>
                        <Table columns={columns} dataSource={templates['1-0']} pagination={false}></Table>
                    </div>
                    <div className="site-layout-card">
                        <h2>Laktoosivaba</h2>
                        <Table columns={columns} dataSource={templates['2-0']} pagination={false}></Table>
                    </div>
                    <div className="site-layout-card">
                        <h2>Gluteeni- ja laktoosivaba</h2>
                        <Table columns={columns} dataSource={templates['3-0']} pagination={false}></Table>
                    </div>
                    <div className="site-layout-card">
                        <h2>Vegan</h2>
                        <Table columns={columns} dataSource={templates['16-0']} pagination={false}></Table>
                    </div>
                    </>

                )}

        </Content>
    );
}

export default MenuTemplatesView;