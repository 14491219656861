import React, {useEffect, useState} from "react";
import {Layout, Breadcrumb, Input, Tabs, Table, Tag, Space, Popconfirm, message} from 'antd';
import type {SortableContainerProps, SortEnd} from 'react-sortable-hoc';
import type {ColumnsType} from 'antd/es/table';
import {arrayMoveImmutable} from 'array-move';
import {Link} from "react-router-dom"
import '../css/draggable.scss'
import Api from "../api";

import {DeleteOutlined, EditOutlined, MenuOutlined} from '@ant-design/icons';
import NewTrainingVideo from "../components/new-training-video";
import NewTrainingVideoCategory from "../components/new-training-video-category";
import EditTrainingVideoCategories from "../components/edit-training-video-categories";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";
import NewBingoChallenge from "../components/new-bingo-challenge";
import NewBingoCategory from "../components/new-bingo-category";
import EditBingoChallengeCategories from "../components/edit-bingo-challenge-categories";

const {TabPane} = Tabs;
const {Content} = Layout;

interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    index: number;
}

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
    <tr {...props} />
));
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
));

const BingoChallengesView = () => {
    const [challenges, setChallenges] = useState([]);
    const [categories, setCategories] = useState([]);
    const [active, setActive] = useState('fitlap');
    const [dataSource, setDataSource] = useState([]);
    const [allCategories, setAllCategories] = useState([]);


    const loadChallenges = () => {
        Api.get('/admin/bingo/challenges').then(response => {
            setChallenges(response.data.data);
        })
    };

    const deleteChallenge = (id) => {
        Api.delete('/admin/bingo/challenges/' + id).then(res => {
            loadChallenges();
            message.success('Challenge deleted');
        })
    }

    const loadCategories = () => {
        Api.get('/admin/bingo').then(response => {
            setCategories(response.data.data);
            setDataSource(response.data.data);
            setAllCategories(response.data.data);
            console.log(response.data.data)
        })
    };

    useEffect(() => {
        loadChallenges()
        loadCategories()
    }, [active])

    const onSortEnd = ({oldIndex, newIndex}: SortEnd) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el: DataType) => !!el,
            );
            console.log('Sorted items: ', newData);
            setDataSource(newData);
            updateCategoryOrder(newData)
        }
    };

    const updateCategoryOrder = (order) => {
        Api.put('/admin/bingo/categories/order', {"order": order}).then(response => {
            console.log(response.data.data)
            loadCategories()
        })
    }

    const DragHandle = SortableHandle(() => <MenuOutlined style={{cursor: 'grab', color: '#999'}}/>);

    const categoryColumnsDraggable: ColumnsType<DataType> = [
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: "3%",
            className: 'drag-visible',
            render: () => <DragHandle/>,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: "20%",
        },
        {
            title: 'Order',
            dataIndex: 'index',
            key: 'index',
            width: "5%",
        },
        {
            title: '# of challenges',
            key: 'challenges',
            render: (videos, record) => <span>{record.challenges.length} / 9</span>,
            width: 5,
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <Link to={"/bingo/categories/" + record.id}><EditOutlined/> edit</Link>
                </Space>,
            width: 30,
        },

    ];

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '3%'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '10%'
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text, record) => <div dangerouslySetInnerHTML={{__html: text}}/>
        },
        {
            title: 'Marked Done',
            dataIndex: 'users_count',
            key: 'users_count',
            width: '4%'
        },
        {
            title: 'Edit categories',
            render: (categories, record) => <EditBingoChallengeCategories challenge={record} allCategories={allCategories} categories={record.category} loadChallenges={loadChallenges} ></EditBingoChallengeCategories>,
            width: 5
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (categories, record) => categories.map((category) =>
                <Tag
                    className="edit-tag"
                    key={category.id}
                >
                        <span>
                            {category.name}
                        </span>
                </Tag>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <Popconfirm
                        title={`Are you sure you want to delete: ${record.title}?`}
                        onConfirm={() => deleteChallenge(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a key="delete-comment" className="delete-comment"><DeleteOutlined/> delete</a>
                    </Popconfirm>

                    <Space size="middle">
                        <Link to={"/bingo/challenges/" + record.id}><EditOutlined/> edit</Link>
                    </Space>
                </Space>,
            width: '10%',
        },
    ];

    const DraggableContainer = (props: SortableContainerProps) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow: React.FC<any> = ({className, style, ...restProps}) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const renderTabContent = (type) => {
        if (type === 'categories-draggable') {
            return (
                <Table
                    pagination={false}
                    dataSource={dataSource}
                    columns={categoryColumnsDraggable}
                    rowKey="index"
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                />
            )
        }
        return (
            <Table columns={columns} dataSource={challenges} pagination={false}/>
        )
    }

    const changeTab = (value) => {
        setActive(value);
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <h1>Bingo Challenges</h1>

                <Tabs defaultActiveKey="1" onChange={changeTab}>
                    <TabPane tab="Challenges" key="challenges">
                        <NewBingoChallenge loadChallenges={loadChallenges}/>
                        <div style={{marginBottom: 15}}></div>
                        {renderTabContent('challenges')}
                    </TabPane>

                    <TabPane tab="Categories" key="categories-draggable">
                        <NewBingoCategory setCategories={setCategories} categories={categories}
                                          loadCategories={loadCategories}/>
                        <div style={{marginBottom: 15}}></div>
                        {renderTabContent('categories-draggable')}
                    </TabPane>

                </Tabs>
            </div>
        </Content>
    );
}

export default BingoChallengesView;
