import React, {useEffect, useState} from "react";
import {
    Layout, Breadcrumb, Row, Col, Input, Button, Skeleton, Form, InputNumber, Table, Switch, Collapse, message, Space, Popconfirm, Tag, Typography
} from 'antd';
import {useParams, Link} from 'react-router-dom';
import Api from "../api";
import {DeleteOutlined, CheckCircleOutlined, FileSearchOutlined} from "@ant-design/icons";

const {Content} = Layout;

const DefaultSurveyQuestions = () => {
    const params = useParams();
    const [questions, setQuestions] = useState([]);
    const [actions, setActions] = useState([]);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');

    useEffect(() => {
        getQuestions()
    }, [])

    const getQuestions = () => {
        Api.get('/admin/survey').then(response => {
            console.log(response.data);
            setQuestions(response.data);
        })
    }

    const updateQuestion = (key, row) => {

        Api.put('/admin/survey/' + key, row).then(response => {
            getQuestions()
        })
    }

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: '2%',
            editable: false,
        },
        {
            title: 'Question',
            dataIndex: 'question',
            width: '20%',
            editable: true,
        },
        {
            title: 'Suggestion',
            dataIndex: 'suggestion',
            width: '20%',
            editable: true,
        },
        {
            title: 'Active',
            dataIndex: 'active',
            render: (active) => active === 1 ? "Yes" : "No",
            width: '15%',
            editable: true,
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Typography.Link
                onClick={() => save(record.id)}
                style={{
                    marginRight: 8,
                }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Edit
                    </Typography.Link>
                );
            },
        },
    ];

    const isEditing = (record) => record.id === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.id);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();

            const newData = [...questions];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                console.log(index)
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                updateQuestion(newData);
                setQuestions(newData);
                setEditingKey('');
            } else {
                // newData.push(row);
                setQuestions(newData);
                updateQuestion(key, row)
                setEditingKey('');
            }

            console.log(questions)
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const EditableCell = ({
                              editing,
                              dataIndex,
                              title,
                              inputType,
                              record,
                              index,
                              children,
                              ...restProps
                          }) => {

        const inputNode = inputChanger(inputType, dataIndex, record);
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const inputChanger = (inputType, dataIndex, record) => {

        if(Array.isArray(dataIndex)) {
            dataIndex = dataIndex[1]
        } else {
            dataIndex = inputType
        }

        switch(dataIndex) {
            case "active":
                return <Switch checked={record.active} onChange={() => {
                        record.active = !record.active
                }}/>
            case "number":
                return <InputNumber />

            default:
                return <Input />
        }
    }

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === "active" ? 'active' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),

        };
    });

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>surveys</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <h1>Default survey questions</h1>

                {!questions ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (

                    <Form form={form} component={false}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            bordered
                            dataSource={questions}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                            pagination={false}
                        />
                    </Form>

                )}
            </div>
        </Content>
    );
};


export default DefaultSurveyQuestions;