import React, {useEffect, useState} from "react";
import {Breadcrumb, Button, Form, Input, Layout, message, Popconfirm, Skeleton, Space, Table, Tabs, Tag} from 'antd';
import {Link, useParams} from "react-router-dom"
import Api from "../../api";
import {CheckCircleOutlined, DeleteOutlined, SaveOutlined} from "@ant-design/icons";
import {redirect} from "../../helpers";

const {TabPane} = Tabs;
const {Content} = Layout;

const AllergenView = () => {
    const params = useParams();
    const [allergen, setAllergen] = useState([]);



    useEffect(() => {
        getAllergen()
    }, [])

    const getAllergen = () => {
        Api.get('/admin/allergens/' + params.id).then(res => {
            setAllergen(res.data.data)
            console.log(res.data.data)
        })
    }
    const onFinish = (values: any) => {
        let data = {"title": values.title}
        Api.put("/admin/allergens/" + allergen.id, data).then(res => {
            setAllergen(res.data.data)
            return message.success('Allergen updated');
        }).catch(e => {
            return message.error('Allergen update failed');
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        return message.error('Failed: please fix the shown errors');
    };

    const deleteAllergen = () => {
        Api.delete("/admin/allergens/" + allergen.id).then(res => {
            return message.success('Allergen deleted');
            redirect("/ingredients/allergens");
        }).catch(e => {
            return message.error('Allergen delete failed');
        });
    }


    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/ingredients">Ingredients</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/ingredients/allergens">Allergens</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{allergen.title}</Breadcrumb.Item>
            </Breadcrumb>

            <h2>Allergen</h2>
            <div className="site-layout-card">

                {!allergen.title ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Form
                        name="group"
                        initialValues={{
                            title: allergen.title,
                        }}
                        autoComplete="off"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >

                        <Form.Item
                            label="Name"
                            name="title">
                            <Input type={"text"}/>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                <SaveOutlined />
                                Save
                            </Button>
                            <Button style={{marginLeft:"1em"}} type="danger" onClick={() => deleteAllergen()}>
                                <DeleteOutlined />
                                Delete
                            </Button>
                        </Form.Item>

                    </Form>
                )}

            </div>
        </Content>
    );
}

export default AllergenView;