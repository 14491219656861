import React, {useEffect, useState, useTransition} from "react";
import {
    Layout,
    Breadcrumb,
    Input,
    Button,
    Tabs,
    Table,
    Tag,
    Space,
    Popconfirm,
    message,
    Row,
    Form,
    Col,
    Modal, Select, Typography
} from 'antd';
import {Link, useNavigate} from "react-router-dom"
import Api from "../api";
import Icon, {DeleteOutlined, EditOutlined, InfoCircleOutlined, PlusOutlined} from "@ant-design/icons";
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


const {TabPane} = Tabs;
const {Search} = Input;
const {Content} = Layout;
const {Option} = Select;
const {Title} = Typography;

const TableRow = (props) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: props['data-row-key'],
    });
    const style = {
        ...props.style,
        transform: CSS.Translate.toString(transform),
        transition,
        cursor: 'move',
        ...(isDragging
            ? {
                position: 'relative',
                zIndex: 9999,
            }
            : {}),
    };
    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};

const Exercises = () => {
    const [exercises, setExercises] = useState([]);
    const [search, setSearch] = useState("");
    const [visible, setVisible] = useState(false);
    const [exercise, setExercise] = useState('');
    const [activeTab, setActiveTab] = useState('fitlap');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        loadExercises()
    }, [search, activeTab])

    const onSearch = (value) => {
        setSearch(value);
    }

    const loadExercises = () => {
        setLoading(true);

        let params = search ? "?search=" + search : '';

        Api.get('/admin/exercises' + (activeTab === 'fitlap' ? "" : '/user') + params).then(response => {
            response.data.map((e, i) => {
                e.key = e.order ? e.order : e.i;
            })

            setExercises(response.data);
            setLoading(false);
        })
    };

    const deleteExercise = (id) => {
        Api.delete('/admin/exercises/' + id).then(response => {
            loadExercises();
        })
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Muscle group',
            dataIndex: 'muscle_group',
            key: 'muscle_group',
        },
        {
            title: 'Used by users (count)',
            dataIndex: 'workout_diaries_count',
            key: 'workout_diaries_count',
        },
        {
            title: '',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a onClick={() => {
                        setExercise(record);
                        setVisible(true);
                    }}><EditOutlined/></a>

                    <Popconfirm
                        title="Delete the exercise?"
                        onConfirm={() => deleteExercise(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a><DeleteOutlined/></a>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    const userExerciseColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'User',
            dataIndex: 'user_id',
            key: 'user_id',
            render: (_, record) => (
                <Link to={"/user/" + record.user_id}>
                    {record.user_id}
                </Link>
            )
        },
    ];

    const sensors = useSensors(
        useSensor(PointerSensor, {activationConstraint: {distance: 1}}),
    );

    const onDragEnd = ({ active, over }) => {
        console.log('here');
        if (active.id !== over?.id) {
            updateOrder(active, over);

            setExercises((prev) => {
                const activeIndex = prev.findIndex((i) => i.key === active.id);
                const overIndex = prev.findIndex((i) => i.key === over?.id);
                return arrayMove(prev, activeIndex, overIndex);
            });
        }
    };

    const updateOrder = (active, over) => {
        const newOrder = over.data.current.sortable.index + 1;
        const updatedExercise = exercises.find(obj => obj.order === active.id);

        Api.put('/admin/exercises/' + updatedExercise.id + '/order', {new_order: newOrder}).then(res => {
            console.log(res);
        })
    }

    const saveWorkout = (values) => {
        let type = exercise ? 'put' : 'post';
        let query = exercise ? '/admin/exercises/' + exercise.id : '/admin/exercises';

        Api[type](query, values).then(res => {
            setVisible(false);

            loadExercises();
        })
    }

    const changeTab = (value) => {
        setActiveTab(value);
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Exercises</Breadcrumb.Item>
            </Breadcrumb>

            <AddNewExercise setExercise={setExercise} exercise={exercise} visible={visible} setVisible={setVisible}
                            saveWorkout={saveWorkout}/>

            <div className="site-layout-card">
                <Row type="flex" justify="space-between">
                    <Search placeholder="Search exercise" onSearch={onSearch} style={{width: 200, marginRight: 10}}/>
                    <Button onClick={() => setVisible(true)} type="primary" icon={<PlusOutlined/>}>Add new
                        exercise</Button>
                </Row>
                <br/>

                        <Tabs defaultActiveKey="fitlap" onChange={changeTab}>
                            <TabPane tab="Fitlap" key="fitlap">
                                <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]}
                                            onDragEnd={onDragEnd}>
                                    <SortableContext
                                        // rowKey array
                                        items={exercises.map((i) => i.key)}
                                        strategy={verticalListSortingStrategy}
                                    >
                                        <Table
                                            components={{
                                                body: {
                                                    row: TableRow,
                                                },
                                            }}
                                            rowKey="key" loading={loading} pagination={false} dataSource={exercises}
                                            columns={columns}/>
                                    </SortableContext>
                                </DndContext>
                            </TabPane>

                            <TabPane tab="User created exercises" key="user">
                                <Table key="user-table" rowKey={"id"} loading={loading} pagination={false}
                                       dataSource={exercises} columns={userExerciseColumns}/>
                            </TabPane>
                        </Tabs>
            </div>
        </Content>
    );
}

const AddNewExercise = ({visible, setVisible, saveWorkout, exercise, setExercise}) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (visible && exercise) {
            form.setFieldsValue({
                name: exercise.name,
                type: exercise.type,
                muscle_group: exercise.muscle_group
            })
        } else {
            form.resetFields();
        }
    }, [visible])

    return (
        <Modal
            title={<div style={{textAlign: "center"}}>Exercise</div>}
            visible={visible}
            onOk={() => {
                setVisible(false);
                setExercise('');
            }}
            onCancel={() => {
                setVisible(false);
                setExercise('');
            }}
            footer={false}
        >
            <Row type="flex" justify="center">
                <Col span={24} md={20} lg={16}>
                    <Form
                        className="exercise-form"
                        name="exercise_form"
                        onFinish={saveWorkout}
                        style={{
                            marginTop: 20,
                            maxWidth: 'none',
                        }}
                        form={form}
                        initialValues={{
                            type: "weight",
                            name: exercise ? exercise.name : ''
                        }}
                        autoComplete="off"
                    >

                        <Form.Item required labelCol={{span: 24}} label={"Name"} name={'name'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item required labelCol={{span: 24}} label={<div>Type</div>} name={'type'}>
                            <Select defaultValue={exercise ? exercise.type : "weight"} name="type">
                                <Option value="weight">Jõuharjutus</Option>
                                <Option value="body_weight">Keharaskusega</Option>
                                <Option value="cardio">Kardio</Option>
                                <Option value="group_exercise">Rühmatrenn</Option>
                                <Option value="fitlap_exercise">Fitlapi video</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item labelCol={{span: 24}} label={"Muscle group"} name={'muscle_group'}>
                            <Input/>
                        </Form.Item>

                        <Form.Item className="text-center">
                            <Button block type="primary" htmlType="submit">Save</Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    )
}


export default Exercises;