import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Row,
    Col,
    Input,
    Button,
    Skeleton,
    List,
    Upload,
    Form,
    Image,
    Space,
    Divider,
    Select, InputNumber, Checkbox, Table, Typography, message, Alert, Popconfirm, Switch
} from 'antd';
import {DeleteOutlined, EyeInvisibleOutlined, UploadOutlined} from "@ant-design/icons";
import {useParams, Link, useNavigate} from 'react-router-dom';
import TextArea from "antd/es/input/TextArea";
import Api from "../api";
import config from "../config/config";
import ReactQuill from "react-quill";
import Search from "antd/lib/input/Search";
import {set, setIn} from "immutable";
import IngredientList from "../components/ingredient-list";
import IngredientSearch from "../components/ingredient-search";
import {redirect} from "../helpers";

const {Option} = Select;
const {Title} = Typography;
const {Content} = Layout;

const RecipeView = () => {
    const params = useParams();
    const [recipe, setRecipe] = useState('');
    const [categories, setCategories] = useState(false);
    const [mealTypes, setMealTypes] = useState('');
    const [search, setSearch] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const isEditRecipe = !!params.id;
    const navigate = useNavigate();

    useEffect(() => {
        if (isEditRecipe) {
            setLoading(true);

            Api.get('/admin/recipes/' + params.id).then(res => {
                setMealTypes(getMealTypes(res.data.data))
                setRecipe(res.data.data)
                setIngredients(res.data.data.ingredients)
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                setError(true);
            })
        }

        loadCategories();
    }, [])

    const loadCategories = () => {
        if (categories === false) {
            Api.get('/admin/recipes/functions/categories').then(res => {
                setCategories(res.data)
            })
        }
    }

    const hasBitwise = (values, value) => {
        if (values === 0) {
            return false
        }
        return ((values & value) === value);
    }

    const getMealTypes = (test) => {
        const types = [];


        if (hasBitwise(test.meal_type, config.mealTypes.breakfast)) {
            types.push(config.mealTypes.breakfast)
        }
        if (hasBitwise(test.meal_type, config.mealTypes.lunch)) {
            types.push(config.mealTypes.lunch)
        }
        if (hasBitwise(test.meal_type, config.mealTypes.dinner)) {
            types.push(config.mealTypes.dinner)
        }
        if (hasBitwise(test.meal_type, config.mealTypes.supper)) {
            types.push(config.mealTypes.supper)
        }
        console.log("meal types:" + types)

        return types
    }

    const calculateMealType = (recipe) => {
        let value = 0;

        if (recipe.breakfast) {
            value += config.mealTypes.breakfast;
        }

        if (recipe.lunch_and_dinner) {
            value += config.mealTypes.lunch;
            value += config.mealTypes.dinner;
        }
        if (recipe.supper) {
            value += config.mealTypes.supper;
        }

        return value;
    }

    const modules = {
            toolbar: [
                [{'header': [1, 2, false]}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link'],
                ['clean'],
                ['emoji']
            ],
            "emoji-toolbar": true,
        },
        formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link',
            'emoji'
        ]

    const forVeganOnlyOptions = [
        {label: "-- Vali --", value: 0},
        {label: "1 - Sobib ka Veganile", value: 1},
        {label: "2 - Ainult Veganile", value: 2},
    ]

    const uploadImage = (value) => {
        const formData = new FormData();

        // Update the formData object
        formData.append(
            "image", value.file.originFileObj
        );

        if (value.file.status !== 'uploading') {
            Api.post('admin/recipes/' + recipe.id + '/image', formData).then(res => {
                const updatedRecipe = setIn(recipe, ['image'], res.data.data.thumbnail + "?date=" + Date.now())
                setRecipe(updatedRecipe)
                value.fileList = []
                return message.success(`${value.file.name} file uploaded successfully`);
            }).catch(e => {
                return message.error(`${value.file.name} file upload failed.`);
            });
        }

    }

    const setIngredient = (ingredient) => {
        const isFound = ingredients.some(element => {
            return element.id === ingredient.id
        })

        if (isFound) {
            return message.error(`Toiduaine on juba nimekirjas`);
        } else {
            ingredient.pivot_energy = 0;
            ingredient.amount = 0;
            setIngredients(current => [...current, ingredient])
            setSearch([])
        }
    }

    function handleIsSimpleChange(event) {
        const updatedData = setIn(recipe, ['is_simple'], event.target.checked)
        setRecipe(updatedData)
    }

    const onFinish = (values: any) => {
        values.ingredients = {...ingredients};
        values.meal_type = calculateMealType(values)
        console.log('Success:', values);

        if (isEditRecipe) {
            Api.put('admin/recipes/' + recipe.id, values).then(res => {
                return message.success(`Retsept edukalt uuendatud!`);
            }).catch(e => {
                return message.error(`${e.message}`);
            });
        } else {
            Api.post('admin/recipes', values).then(res => {
                message.success(`Retsept edukalt lisatud!`);
                navigate('/recipes');
            }).catch(e => {
                return message.error(`${e.message}`);
            });
        }
    };

    const recipeImage = () => {
        return recipe.image.replace('thumbnail', 'original')
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/recipes">Recipes</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{params.id}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <Divider orientation="left">
                    <Title style={{margin: 0}} level={4}>{isEditRecipe ? "Muuda retsepti" : "Loo retsept"}</Title>
                </Divider>

                {error && (
                    <Row type="flex" justify="center">
                        <Space direction="vertical" style={{textAlign: "center"}}>
                            <Alert type="error" message="Recipe not found" showIcon={true}></Alert>
                            <Button type="primary"><Link to="/recipes">Go Back</Link></Button>
                        </Space>
                    </Row>
                )}

                <div>
                    <Form
                        labelCol={{span: 5}}
                        wrapperCol={{span: 16}}
                        name={"image-upload"}
                    >
                        <Form.Item
                            label="Retsepti pilt"
                            name="image"
                        >
                            <Space direction={"vertical"}>
                                {recipe.image && (
                                    <Image style={{maxWidth: 360}} src={recipeImage()}></Image>
                                )}

                                <Upload onChange={uploadImage}>
                                    <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                                </Upload>
                            </Space>
                        </Form.Item>
                    </Form>

                    <Form
                        name="recipe"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 16}}
                        key={recipe}
                        initialValues={{
                            original_name: recipe.original_name,
                            slug: recipe.slug,
                            seo_title: recipe.seo_title,
                            seo_description: recipe.seo_description,
                            preparation_time: recipe.preparation_time,
                            is_active: recipe.is_active,
                            is_public: recipe.is_public,
                            is_featured: recipe.is_featured,
                            is_basic: recipe.is_basic,
                            is_simple: recipe.is_simple,
                            is_from_store: recipe.is_from_store,
                            is_restaurant_meal: recipe.is_restaurant_meal,
                            breakfast: mealTypes.includes(config.mealTypes.breakfast) ? 'checked' : null,
                            lunch_and_dinner: mealTypes.includes(config.mealTypes.lunch) ? 'checked' : null,
                            supper: mealTypes.includes(config.mealTypes.supper) ? 'checked' : null,
                            recipe_category_id: recipe.recipe_category_id,
                            instructions: recipe.instructions,
                            extra_keywords: recipe.extra_keywords,
                            keywords: recipe.keywords,
                            notes: recipe.notes,
                            is_gluten_free: recipe.is_gluten_free,
                            is_lactose_free: recipe.is_lactose_free,
                            is_vegetarian_food: recipe.is_vegetarian_food,
                            is_pescatarian_food: recipe.is_pescatarian_food,
                            for_vegan_only: recipe.for_vegan_only,
                            is_user_public: recipe.is_user_public,
                            is_custom_energy: recipe.is_custom_energy,
                        }}
                        autoComplete="off"
                        onFinish={onFinish}
                    >
                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Retsepti info</Title>
                        </Divider>

                        {recipe.user_id && (
                            <div>
                                <Form.Item
                                    label="User ID"
                                    name="u_id">
                                    <Link to={"/user/" + recipe.user_id}>{recipe.user_id}</Link>
                                </Form.Item>

                                <Form.Item label="Is public" name="is_user_public" valuePropName="checked"><Checkbox
                                    value="1"></Checkbox></Form.Item>

                                <Divider/>
                            </div>
                        )}


                        <Form.Item
                            label="Retsepti nimi"
                            name="original_name"
                            rules={[{required: true, message: 'Palun sisesta retsepti avalik nimi'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Slug"
                            name="slug"
                            rules={[{required: true, message: 'Palun sisesta retsepti slug'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="SEO Title"
                            name="seo_title">
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="SEO Description"
                            name="seo_description">
                            <TextArea/>
                        </Form.Item>

                        <Form.Item label="Kategooria" name="recipe_category_id"
                                   rules={[{required: true, message: 'Palun vali retsepti kategooria'}]}>
                            <Select options={categories} value={recipe.recipe_category_id}></Select>
                        </Form.Item>

                        <Form.Item
                            label="Valmistamise aeg (min)"
                            name="preparation_time">
                            <InputNumber min={0} controls={true}/>
                        </Form.Item>

                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Retsepti seaded</Title>
                        </Divider>

                        <Form.Item label="Fikseeritud kaloraaž" name="is_custom_energy"
                                   valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Lihtne" name="is_simple" valuePropName="checked"><Checkbox
                            value="1" onChange={handleIsSimpleChange}></Checkbox></Form.Item>

                        <Form.Item label="Aktiivne" name="is_active" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Avalik" name="is_public" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Esiletõstetud" name="is_featured" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Basic" name="is_basic" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Otse poest" name="is_from_store" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Valmistoit toidukohas" name="is_restaurant_meal"
                                   valuePropName="checked"><Checkbox value="1"></Checkbox></Form.Item>


                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Toidukord</Title>
                        </Divider>

                        <Form.Item label="Hommikusöök" name="breakfast" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Lõunasöök / õhtusöök" name="lunch_and_dinner"
                                   valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>
                        <Form.Item label="Hiline õhtusöök" name="supper" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Välistused</Title>
                        </Divider>

                        <Form.Item label="Gluteenivaba" name="is_gluten_free" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Laktoosivaba" name="is_lactose_free" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Taimetoit" name="is_vegetarian_food" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Pescatarian" name="is_pescatarian_food" valuePropName="checked"><Checkbox
                            value="1"></Checkbox></Form.Item>

                        <Form.Item label="Vegan" name="for_vegan_only">
                            <Select options={forVeganOnlyOptions} value={recipe.for_vegan_only}></Select>
                        </Form.Item>

                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Toiduained</Title>
                        </Divider>

                        <Form.Item wrapperCol={18} label="Toiduained" name="ingredients">
                            <Space direction="vertical" style={{width: "100%"}}>
                                <Space>
                                    <Alert message="Toiduained peaksid kokku olema 375 kcal." type="warning"/>
                                    <Alert message="Maitseaineteks loetakse toiduaineid, mille kogus on 0/NaN."
                                           type="warning"/>
                                </Space>

                                <IngredientList key={ingredients} setIngredients={setIngredients}
                                                ingredients={ingredients}/>
                            </Space>
                        </Form.Item>

                        <Form.Item label="Otsi toiduainet">
                            <IngredientSearch searchResponse={search} setSearchResponse={setSearch}
                                              onClickEvent={setIngredient} onlyId={false}/>
                        </Form.Item>

                        <Form.Item label="Kirjeldus" name="instructions">
                            <ReactQuill style={{minHeight: 100}} modules={modules} formats={formats}/>
                        </Form.Item>

                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>Lisad</Title>
                        </Divider>

                        <Form.Item label="Märkmed (nähtav vaid adminis)" name="notes">
                            <TextArea/>
                        </Form.Item>

                        <Form.Item label="Keywords(default + extra)" name="keywords">
                            <TextArea disabled="true"/>
                        </Form.Item>
                        <Form.Item label="Extra keywords" name="extra_keywords">
                            <TextArea/>
                        </Form.Item>


                        <Form.Item wrapperCol={{offset: 5, span: 16}}>
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    Salvesta
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Content>
    );
}

export default RecipeView;