import React, {useEffect, useState} from "react";
import {
    Layout, Breadcrumb,Input, Skeleton, Form, InputNumber, Table, Space, Popconfirm, Typography, Switch
} from 'antd';
import Api from "../api";

const {Content} = Layout;

const Actions = () => {
    const [actions, setActions] = useState([]);
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');


    useEffect(() => {
        loadSettings();
    }, [])

    const loadSettings = () => {
        Api.get('/admin/levels/settings').then(response => {
            setActions(response.data.actions);
        })
    }

    const updateActions = (dataObject) => {
        Api.put('/admin/levels/settings/actions', dataObject).then(response => {
            setActions(response.data);
        })
    }

    const inputChanger = (inputType, dataIndex, record) => {

        if(Array.isArray(dataIndex)) {
            dataIndex = dataIndex[1]
        } else {
            dataIndex = inputType
        }

        switch(dataIndex) {
            case "number":
                return <InputNumber />
            case "times":
                return <InputNumber />
            case "day":
                return <Switch checked={record.limit.day} onChange={() => {
                    if(record.limit.week !== true) {
                        record.limit.day = !record.limit.day
                    }
                }}/>

            case "week":
                return <Switch checked={record.limit.week} onChange={() => {
                    if(record.limit.day !== true) {
                        record.limit.week = !record.limit.week
                    }
                }} />
            default:
                return <Input />
        }
    }

    const EditableCell = ({
                              editing,
                              dataIndex,
                              title,
                              inputType,
                              record,
                              index,
                              children,
                              ...restProps
                          }) => {
        const inputNode = inputChanger(inputType, dataIndex, record);
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        style={{
                            margin: 0,
                        }}
                        rules={[
                            {
                                required: true,
                                message: `Please Input ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey('');
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();

            const newData = [...actions];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                updateActions(newData);
                setActions(newData);
                setEditingKey('');
            } else {
                newData.push(row);
                setActions(newData);
                setEditingKey('');
            }
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        {
            title: 'Event',
            dataIndex: 'key',
            width: '5%',
            editable: false,
        },
        {
            title: 'Points',
            dataIndex: 'points',
            width: '15%',
            editable: true,
        },
        {
            title: 'Day',
            dataIndex: ['limit','day'],
            render: (limit) => limit === true ? "Selected" : "",
            // render: (limit, record) => <Space><span>{limit === true ? "YES" : "NO"}</span></Space>,
            width: '10%',
            editable: true,
        },
        {
            title: 'Week',
            dataIndex: ['limit','week'],
            render: (limit) => limit === true ? "Selected" : "",
            // render: (limit, record) => <Space><span>{limit === true ? "YES" : "NO"}</span></Space>,
            width: '10%',
            editable: true,
        },
        {
            title: 'Times',
            dataIndex: ['limit','times'],
            // render: (limit, record) => <Space><span>{limit === true ? "YES" : "NO"}</span></Space>,
            width: '10%',
            editable: true,
        },
        {
            title: 'Limited',
            dataIndex: "limit",
            render: (limit) => limit.times > 0 ? <span>{limit.times} per {limit.day === true ? "day" : ""}{limit.week === true ? "week" : ""}</span> : "Unlimited",
            // render: (limit, record) => <Space><span>{limit === true ? "YES" : "NO"}</span></Space>,
            width: '10%',
            editable: false,
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Typography.Link
                onClick={() => save(record.key)}
                style={{
                    marginRight: 8,
                }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
                ) : (
                    <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                        Edit
                    </Typography.Link>
                );
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === "points" ? 'number' : 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),

        };
    });


    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Levels</Breadcrumb.Item>
                <Breadcrumb.Item>Actions</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">

                <h1>Level events (Muuta ainult kui tead mis teed)</h1>

                {!actions ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Form form={form} component={false}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            bordered
                            dataSource={actions}
                            columns={mergedColumns}
                            rowClassName="editable-row"
                            pagination={false}
                        />
                    </Form>
                )}
            </div>
        </Content>
    );
}

export default Actions;