import React, {useEffect, useState} from "react";
import {Breadcrumb, Input, Layout, message, Popconfirm, Space, Table, Tabs, Tag} from 'antd';
import {Link} from "react-router-dom"
import Api from "../api";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined} from '@ant-design/icons';
import NewBarcodeIngredient from "../components/new-barcode-ingredient";
import NewIngredient from "../components/new-ingredient";

const {TabPane} = Tabs;
const {Search} = Input;
const {Content} = Layout;

const Ingredients = () => {
    const [ingredients, setIngredients] = useState([]);
    const [active, setActive] = useState('fitlap');
    const [meta, setMeta] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [search, setSearch] = useState("");
    const idIndex = active === 'history' ? 'ingredient_id' : 'id';

    useEffect(() => {
        loadIngredients();
    }, [active])

    const columns = [
        {
            title: 'ID/verified',
            dataIndex: 'id',
            key: 'id',
            width: 30,
            render: (verified, record) => <Space><span>{record.id}</span><span><CheckCircleOutlined
                style={record.verified === 1 ? {color: 'green'} : {color: "red"}}/></span></Space>,
        },
        {
            title: 'Barcode',
            dataIndex: ["barcode", "0", "barcode"],
            key: 'barcode',
            width: 30,
        },
        {
            title: 'logo_image',
            dataIndex: 'logo_image',
            key: 'logo_image',
            width: 30,
            render: img => (<img style={{width: 50}} src={img} alt=""/>)
        },
        {
            title: 'Name',
            dataIndex: 'original_name',
            key: 'original_name',
            render: (name, record) => {
                return (
                    <Link to={"/ingredient/" + record[idIndex]}>{name}</Link>
                )
            },
            width: 200,
        },
        {
            title: 'Energy (kcal)',
            dataIndex: 'energy',
            key: 'energy',
            width: 30,
        },

        {
            title: 'Carbohydrate (g)',
            dataIndex: 'carbohydrate',
            key: 'carbohydrate',
            width: 30,
        },
        {
            title: 'Protein (g)',
            dataIndex: 'protein',
            key: 'protein',
            width: 30,
        },
        {
            title: 'Fat (g)',
            dataIndex: 'fat',
            key: 'fat',
            width: 30,
        },
        {
            title: 'Fiber (g)',
            dataIndex: ['nutridata', 'fiber'],
            key: 'fiber',
            width: 30,
        },

        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id',
            width: 30,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            hidden: active === 'history' ? true : false,
            render: text => <span><Tag color={text === 1 ? "green" : "red"}>{text === 1 ? "active" : "inactive"}</Tag></span>,
        },
        {
            title: "Action",
            key: "action",
            hidden: active === 'history' ? true : false,
            render: (text, record) =>
                <Space size="middle">
                    <Popconfirm
                        title={`Verify macros for ${record.original_name}`}
                        onConfirm={() => verifyIngredientMacros(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a style={record.verified === 1 ? {display: "none"} : null}><CheckOutlined/> check</a>
                    </Popconfirm>
                    <Popconfirm
                        title={`Are you sure you want to delete: ${record.original_name}?`}
                        onConfirm={() => deleteIngredient(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a key="delete-comment" className="delete-comment"><DeleteOutlined/> delete</a>
                    </Popconfirm>
                </Space>,
            width: 30,
        },
    ];

    const loadIngredients = (nextPage, keywords) => {
        let params = `&per_page=${pageSize}`

        if (keywords || search) {
            if(keywords && keywords !== search) {
                params += `&keywords=${keywords}`;
            } else {
                params += `&keywords=${!search ? keywords : search}`;
            }

        }

        if (nextPage) {
            params += `&page=${nextPage}`;
        } else {
            params += '&page=1';
        }

        params += '&order_by=id';

        Api.get('/admin/ingredients?type=' + active + params).then(response => {
            setPage(response.data.current_page);
            setMeta(response.data);
            setIngredients(response.data.data);
        })
    };

    const renderTabContent = (type) => {
        return (
            <Table pagination={{pageSize: pageSize, total: meta.total, current: meta.current_page}} onChange={onChange} columns={columns}
                   dataSource={ingredients} size="small"/>
        )
    }

    const onSearch = (value) => {
        setSearch(value);
        loadIngredients(null, value);
    }

    const onChange = (data) => {
        setPageSize(data.pageSize);
        loadIngredients(data.current);
    };

    const changeTab = (value) => {
        setActive(value);
    }

    const deleteIngredient = (ingredient) => {
        Api.delete('/admin/ingredients/' + ingredient).then(res => {
            loadIngredients();
            message.success('Ingredient deleted');
        })
    }

    const verifyIngredientMacros = (ingredient) => {
        Api.get('/admin/ingredients/' + ingredient + '/verify').then(res => {
            if (res.data === 1) {
                message.success('Ingredient macros are in spec');
            } else {
                message.error('Ingredient macros are out of spec');
            }

            loadIngredients();
        })
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Ingredients</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <h1>Ingredients ({meta.total}) <Search placeholder="search" onSearch={onSearch}
                                                       style={{width: 200, float: "right", marginRight: 10}}/></h1>

                <Space style={{marginBottom: 20}}>
                    <NewBarcodeIngredient/>
                    <NewIngredient/>
                </Space>

                <Tabs defaultActiveKey="1" onChange={changeTab}>
                    <TabPane tab="Fitlap" key="fitlap">
                        {renderTabContent('fitlap')}
                    </TabPane>
                    <TabPane tab="Barcodes" key="user">
                        {renderTabContent('user')}
                    </TabPane>
                    <TabPane tab={<div>Barcodes <Tag color="red">Unverified</Tag></div>} key="user_unverified">
                        {renderTabContent('user_unverified')}
                    </TabPane>
                    <TabPane tab="History" key="history">
                        {renderTabContent('history')}
                    </TabPane>
                </Tabs>
            </div>
        </Content>
    );
}

export default Ingredients;