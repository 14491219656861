import React, {useEffect, useState} from "react";
import {Form, Input, Button, Space, Modal, message, Table, Popconfirm, Card, InputNumber, Select, Alert} from 'antd';
import {DeleteOutlined, MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import Api from "../../api";
import {set, setIn} from "immutable";

const StepsChallange = () => {
    const [currentChallange, setCurrentChallange] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeTabKey, setActiveTabKey] = useState('report');

    useEffect(() => {
        getCurrentChallenge()
    }, [])

    const onTab1Change = (key) => {
        setActiveTabKey(key);
    };
    const getCurrentChallenge = () => {
        Api.get("/v2/competitions").then(res => {
            setCurrentChallange(res.data[0])
            console.log(res.data[0])
        });
    }

    const onFinish = (values: any) => {
        setLoading(true)
        Api.get("/admin/reports/steps-challenge?email=" + values.email + "&steps=" + values.steps, values).then(res => {
            setLoading(false)
            return message.success('Report sent to ' + values.email);
        }).catch(e => {
            setLoading(false)
            return message.error('Report generation failed');
        });

        setLoading(false)
    };

    const onFinishStartNew = (values: any) => {
        setLoading(true)

        Api.post("/admin/reports/steps-challenge", values).then(res => {
            setLoading(false)
            return message.success("New step competition started");
        }).catch(e => {
            setLoading(false)
            return message.error('New step competition generation failed');
        });
    };

    const tabList = [
        {
            key: 'report',
            tab: 'Report',
        },
        {
            key: 'challenge',
            tab: 'Challenge',
        },
    ];

    const contentList = {
        report:
            <div>
                <Form
                    name="steps_challange"
                    labelCol={{span: 4}}
                    wrapperCol={{span: 16}}
                    onFinish={onFinish}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{required: true, message: 'Email on nõutud'}]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="Min steps"
                        name="steps">
                        <InputNumber
                            min="1"
                            step="1000"
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{offset: 4, span: 16}}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Generate
                        </Button>
                    </Form.Item>


                </Form>
            </div>,

        challenge: <p>
            <Form
                name="new_challenge"
                labelCol={{span: 4}}
                wrapperCol={{span: 16}}
                onFinish={onFinishStartNew}
                autoComplete="off"
            >
                <Form.Item
                    label="Name"
                    name="name"
                    rules={[{required: true, message: 'Name field is required'}]}>
                    <Input/>
                </Form.Item>

                <Form.Item wrapperCol={{offset: 4, span: 16}}>
                    <Alert style={{margin: '10px 0px'}}
                           message="Nupu vajutamisel lõpetatakse eelmine võistlus ja tehakse uus selle nimega mis üleval kirjas"
                           type="info"
                           showIcon={true}
                           closable
                    />
                    <Button type="primary" htmlType="submit" loading={loading}>
                        End "{currentChallange.name}" and start new
                    </Button>
                </Form.Item>


            </Form>
                </p>,
    };

    return (
        <Card
            style={{ width: '100%' }}
            title={'Steps Challenge - ' + currentChallange.name }
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={key => {
                onTab1Change(key);
            }}
        >
            {contentList[activeTabKey]}
        </Card>
            // <Card title={'Steps Challenge - ' + currentChallange.name }>
            //     <Form
            //         name="steps_challange"
            //         labelCol={{span: 4}}
            //         wrapperCol={{span: 16}}
            //         onFinish={onFinish}
            //         autoComplete="off"
            //     >
            //         <Form.Item
            //             label="Email"
            //             name="email"
            //             rules={[{required: true, message: 'Email on nõutud'}]}>
            //             <Input/>
            //         </Form.Item>
            //
            //         <Form.Item
            //             label="Min steps"
            //             name="steps">
            //             <InputNumber
            //                 min="1"
            //                 step="1000"
            //             />
            //         </Form.Item>
            //
            //         <Form.Item wrapperCol={{offset: 4, span: 16}}>
            //             <Button type="primary" htmlType="submit" loading={loading}>
            //                 Generate
            //             </Button>
            //         </Form.Item>
            //
            //
            //     </Form>
            //
            // </Card>
    );
};

export default StepsChallange;