import React, {useState} from "react";
import {Form, Input, Button, Space, Modal, message} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

const NewTranslation = ({updateTranslation}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [translationKey, setTranslationKey] = useState('');
    const [translationValue, setTranslationValue] = useState('');

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        if (translationKey) {
            updateTranslation(translationKey, translationValue);
            setTranslationKey('');
            setTranslationValue('');
            message.success('translation added!')
            setIsModalVisible(false);
        } else {
            message.error("Add translation key");
            return false;
        }
    };

    const handleCancel = () => {
        setTranslationKey('');
        setTranslationValue('');
        setIsModalVisible(false);
    };

    const updateTranslationKey = (e) => {
        let value = e.target.value;

        setTranslationKey(value.replace(/\s/g, ""));
    }

    return (
        <>
            <Button className="add-translation-btn" type="primary" onClick={showModal}>
                <PlusOutlined/> Add new translation
            </Button>

            <Modal title="Add new translation"
                   visible={isModalVisible}
                   onOk={handleOk}
                   okText="Add translation"
                   onCancel={handleCancel}
            >
                <Space style={{display: 'flex', marginBottom: 8}} align="baseline">
                    <Input value={translationKey} onChange={updateTranslationKey} placeholder="translation.key"/>
                    <Input value={translationValue} onChange={(e) => setTranslationValue(e.target.value)} placeholder="value"/>
                </Space>
            </Modal>
        </>

    );
};

export default NewTranslation;