import React, {useEffect, useState} from "react";
import {Form, Input, Button, Space, Modal, message, InputNumber, Switch} from 'antd';
import {MinusCircleOutlined, PlusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import Api from "../api";
import CheckableTag from "antd/lib/tag/CheckableTag";

const EditTrainingVideoCategories = ({video, allCategories, categories, loadVideos}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);


    const showModal = () => {
        let list = categories.map((category) =>
            category.name
        )
        setSelectedCategories(list)
        setIsModalVisible(true);
    };

    useEffect(() => {

    }, [])

    const onFinish = (values: any) => {

    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleChange = (category, checked: boolean) => {
        const nextSelectedTags = checked ? [...selectedCategories, category.name] : selectedCategories.filter(t => t !== category.name);
        console.log('You are interested in: ', nextSelectedTags);
        checked ? addCategoryToVideo(category.id, video.id) :  removeCategoryFromVideo(category.id, video.id)
        setSelectedCategories(nextSelectedTags);
    };

    const addCategoryToVideo = (categoryId, videoId) => {
        Api.put('/admin/training-videos/edit/add/' + videoId + '/' + categoryId).then(response => {
            loadVideos();
        })
    }

    const removeCategoryFromVideo = (categoryId, videoId) => {
        Api.put('/admin/training-videos/edit/remove/' + videoId + '/' + categoryId).then(response => {
            loadVideos();
        })
    }

    return (
        <>
            <PlusCircleOutlined onClick={showModal} style={{marginRight:15}}/>

            <Modal title='Add category'
                   visible={isModalVisible}
                   onCancel={handleCancel}
                   footer={null}
            >
                <Space size={[0, 8]} wrap>
                    {allCategories.map((category) => (
                        <CheckableTag
                            key={category.name}
                            checked={selectedCategories.indexOf(category.name) > -1}
                            onChange={checked => handleChange(category, checked)}
                        >
                            {category.name}
                        </CheckableTag>
                    ))}
                </Space>
            </Modal>
        </>

    );
};

export default EditTrainingVideoCategories;