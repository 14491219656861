import React, {useState} from "react";
import {Button, Space, Modal, Avatar, List} from 'antd';
import config from "../config/config";

const IngredientIconPicker = ({setIngredient, setIngredientImage, ingredient, form}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [allowedIcons, setAllowedIcons] = useState([]);

    const showModal = () => {
        setIsModalVisible(true);
        getAllowedIcons();
    };

    const handleOk = () => {

    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const changeIcon = (item) => {
        ingredient.image = item;

        setIngredient(ingredient);
        setIngredientImage(item);

        form.setFieldsValue({
            image: item,
        })

        setIsModalVisible(false);
        // setIngredient(ingredient)
    };

    const getAllowedIcons = () => {
        let allowedIcons = config.ingredient_icons;

        let updated = allowedIcons.map(item => {
            return 'https://fitlap.ee/wp-content/images/icons/gastronomy/' + item + '.svg'
        })

        setAllowedIcons(updated)
    }

    return (
        <>
            <Button className="add-translation-btn" type="primary" onClick={showModal} style={{marginLeft:30}}>
                Muuda
            </Button>

            <Modal title="Choose icon"
                   visible={isModalVisible}
                   className="ingredient-icon-picker"
                   onOk={handleOk}
                   okText="Sulge"
                   onCancel={handleCancel}
            >
                <Space style={{display: 'flex', marginBottom: 8}} align="baseline">

                    <List
                        grid={{
                            gutter: 6,
                            xs: 1,
                            sm: 2,
                            md: 4,
                            lg: 4,
                            xl: 6,
                            xxl: 6,
                        }}
                        dataSource={allowedIcons}
                        renderItem={(item) => (
                            <List.Item key={item} onClick={(e) => {changeIcon(item)}}>
                                <Avatar src={item}></Avatar>
                            </List.Item>
                        )}
                    />

                </Space>
            </Modal>
        </>

    );
};

export default IngredientIconPicker;