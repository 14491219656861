var urlParams = new URLSearchParams(window.location.search);
let config =  {
    dev: true,
    lang: null, // correct language is selected in i18n.js
    lang_options : ['et', 'en', 'lv'] ,
    default_lang: 'et',
    email: 'info@fitlap.ee',
    amember_signup: '/amember/signup',
    public_app_url: 'http://localhost:3000',
    domain: 'test',
    currency: '€',
    api: {
        url: 'https://api.fitlap.ee/',
        version: 'v1',
    },
    wordpress: {
        api: "https://wp.fitlap.ee/wp-json/wp/v2",
        author: 3,
        slugs: {
            terms: 'privaatsustingimused',
            cookie_policy: 'kupsiste-kasutamine',
            terms_of_sale: 'muugi-ja-kasutustingmused',
            faq: 'kkk',
        }
    },
    /*
    logging: {
        key: 'AIzaSyDa_N5kt22BOgmJ1TcR1RK-bNmWemvaTJI',
        service: 'test-app.fitlap.ee',
        version: null
    },*/
    analytics_id: 'G-MMVM1RHGRX',
    optimize_id: 'GTM-K6TK7W5',
    experiments: {
        //signup: "adCA0N-YR6e-pg7SIlPqMA"
    },
    facebook: {
        app_id: 1989277887983249,
        pixel_id: '',
        locale: 'et_EE',
        group: 'https://www.facebook.com/groups/kaalustalla/'
    },
    /*
    helpcrunch: {
        application_name: "fitlaptest",
        application_id: 1,
        application_secret: "08z+CMxsvExxCedu2UXOySqtSQ/oQUpIRBmsjyxOLFtn+2Emd2ktO0uYhgUDgxrrz1KhSIEIQO7v4/adJQ5lsA=="
    },*/
    //hotjar: 799663,
    //getsitecontrol: '8wlozyrw',
    //amplitude: 'ea7113eeba40ebda41c29d2473475b31',
    aimtell: '22105',
    url_translations: {
        et: {
            'recipes' : 'retseptid',
            'blog-post': 'postitus',
            'blog': 'blogi',
            'user': 'user',
            'signup': 'liitu',
            'signup-email': 'liitu/email',
            'forgot-password': 'unustasin-parooli',
            'testimonials': 'edulood',
            'faq': 'kkk',
            'toc': 'privaatsustingimused',
            'cookies': 'kupsiste-kasutamine',
            'terms-of-sale': 'muugi-ja-kasutustingmused',
            'partners': 'partnerid',
            'contact': 'kontakt',
            'shopping-list-public': 'shopping-list-public'
        },
        en: {
            'recipes' : 'recipes',
            'blog-post': 'blog-post',
            'blog': 'blog',
            'user': 'user',
            'signup': 'signup',
            'signup-email': 'signup/email',
            'forgot-password': 'forgot-password',
            'testimonials': 'testimonials',
            'faq': 'faq',
            'toc': 'terms-and-conditions',
            'cookies': 'about-cookies',
            'terms-of-sale': 'terms-of-sale',
            'partners': 'partners',
            'contact': 'contact',
            'shopping-list-public': 'shopping-list-public'
        },
        lv: {
            'recipes' : 'receptes',
            'blog': 'blogs',
            'blog-post': 'bloga-ieraksts',
            'user': 'lietotajs',
            'login': 'pieslegsanas',
            'signup': 'registracija',
            'signup-email': 'registracija/email',
            'forgot-password': 'aizmirsu-paroli',
            'testimonials': 'atsauksmes',
            'faq': 'buj',
            'toc': 'noteikumi',
            'cookies': 'sikdatnes',
            'terms-of-sale': 'terms-of-sale',
            'partners': 'partneri',
            'contact': 'kontakti',
            'shopping-list-public': 'iepirkumu-saraksts-publisks'
        }
    },
    user: {
        male: 1,
        female: 2,
        goal_lose_weight: 1,
        goal_maintain_weight: 2,
        goal_gain_weight: 3,
    },
    unit_system: {
        metric: 1,
        imperial: 2,
        display_amount: 1,
        display_custom_amount: 2,
        display_amount_imperial: 4,
        display_custom_amount_imperial: 8,
    },
    rounding : {
        up: 1,
        down: 2,
        disabled: 4
    },
    intolerances: {
      gluten_free: 1,
      lactose_free: 2,
      diabetes: 4,
      vegeterian: 8,
      vegan: 16,
      constipation: 32
    },
    mealTypes: {
        breakfast: 1,
        lunch: 2,
        dinner: 4,
        supper: 8
    },
    feature: {
        unit_system_enabled: true,
        imperial_ingredients: true,
        banner: 'https://wp.fitlap.ee/wp-json/acf/v3/options/options',
        hide_banner_for_new_users: true,
        hide_banner_for_ios: true,
        shop_link: true,
        basic_promo: false,
        onboarding: {
            enabled: true,
            days: 1,
            messages: [3],
            basic_recipe_ids: {
                1: [17, 30, 43],
                2: [17, 30, 43],
                4: [17, 30, 43],
                8: [17, 30, 43]
            }
        },
        training_groups: ['muscles', 'aerobic', 'stretching'],
        inactive_banner: "https://fitlap.ee/pere-toitumiskava-2",
        recipe_price: false,
        new_user_discount: true
    },
    recipe: {
        condiments_category: 8
    },
    billing_plan: {
        type: 'default',
        trial_period: 3
    },
    free_products: [4, 7, 11, 22],
    placeholder_recipe_id: 1281,
    affiliate: "https://fitlap.ee/amember/aff/go/",
    nutridata_fields: {
        "alcohol": "Alkohool, g",
        "water": "Vesi, g",
        "ash": "Tuhk, g",
        "fiber": "Kiudained, g",
        "starch": "Tärklis, g",
        "sugars": "Suhkrud, kokku, g",
        "sucrose": "Sahharoos, g",
        "lactose": "Laktoos, g",
        "maltose": "Maltoos, g",
        "glucose": "Glükoos, g",
        "fructose": "Fruktoos, g",
        "galactose": "Galaktoos, g",
        "fatty_acids": "Rasvhapped, kokku, g",
        "saturated_fatty_acids": "Küllastunud rasvhapped, g",
        "monounsaturated_fatty_acids": "Monoküllastumata rasvhapped, g",
        "polyunsaturated_fatty_acids": "Polüküllastumata rasvhapped, g",
        "trans_fatty_acids": "Trans-rasvhapped, g",
        "palmic_acid": "Palmitiinhape (C16), g",
        "stearic_acid": "Steariinhape (C18), g",
        "linolic_acid": "Linoolhape (C18:2), g",
        "linoleic_acid": "Linoleenhape (C18:3), g",
        "cholesterol": "Kolesterool, mg",
        "sodium": "Naatrium, mg",
        "potassium": "Kaalium, mg",
        "calcium": "Kaltsium, mg",
        "magnesium": "Magneesium, mg",
        "phosphorus": "Fosfor, mg",
        "iron": "Raud, mg",
        "zinc": "Tsink, mg",
        "copper": "Vask, mg",
        "mangan": "Mangaan, mg",
        "iodine": "Jood, µg",
        "selenium": "Seleen, µg",
        "chromium": "Kroom, µg",
        "nickel": "Nikkel, µg",
        "vitamin_a": "Vitamiin A, RE",
        "retinol": "Retinool, µg",
        "beta_carotene_equivalent": "Beeta-karoteeni ekvivalent, BCE",
        "vitamin_d": "Vitamiin D, µg",
        "vitamin_d3": "Vitamiin D3, µg",
        "vitamin_e": "Vitamiin E, αTE",
        "vitamin_k": "Vitamiin K, µg",
        "vitamin_b1": "Vitamiin B1, mg",
        "vitamin_b2": "Vitamiin B2, mg",
        "niacin_equivalent": "Niatsiini ekvivalent, kokku, NE",
        "niacin": "Niatsiin, mg",
        "niacin_equivalent_tryptophan": "Niatsiini ekviv. trüptofaanist, mg",
        "pantothenic_acid": "Pantoteenhape, mg",
        "vitamin_b6": "Vitamiin B6, mg",
        "biotin": "Biotiin, µg",
        "folates": "Folaadid, µg",
        "vitamin_b12": "Vitamiin B12, µg",
        "vitamin_c": "Vitamiin C, mg",
        "salt_equivalent": "Soola ekvivalent, g"
    },
    ingredient_icons: [
        'beans',
        'cereals',
        'cheese-1',
        'onion',
        'pepper-1',
        'pineapple',
        'potatoes-2',
        'apple',
        'avocado',
        'biscuit',
        'blueberries',
        'bread',
        'butter',
        'cake',
        'can',
        'cauliflower',
        'cherries',
        'coffee-3',
        'croissant',
        'doughnut',
        'flour',
        'fries',
        'honey-1',
        'meat-1',
        'meat-2',
        'milk',
        'mushroom',
        'noodles',
        'oil',
        'olives',
        'peach',
        'pepper',
        'pickles',
        'salami',
        'steak',
        'stew',
        'toast',
        'water',
        'asparagus',
        'boiled',
        'baguette',
        'bread-2',
        'chili',
        'coffee',
        'cookies',
        'dairy',
        'eggs',
        'fig',
        'garlic',
        'honey',
        'jam',
        'kebab',
        'lemon-1',
        'milk-1',
        'mustard-1',
        'oat',
        'pancakes-1',
        'pasta-1',
        'peas',
        'radish',
        'rice',
        'salad',
        'sausage',
        'seeds',
        'strawberry',
        'tea',
        'turkey',
        'apple-1',
        'bacon',
        'banana',
        'bread-1',
        'broccoli',
        'cabbage',
        'carrot',
        'chips',
        'chives',
        'coconut',
        'corn',
        'egg',
        'fish',
        'flour-1',
        'ham',
        'meat',
        'lemon',
        'onion',
        'pasta',
        'raspberry',
        'salt',
        'shrimp',
        'cheese',
        'chocolate',
        'grapes',
        'orange',
        'pear',
        'pumpkin',
        'salad',
        'salmon',
        'tomato',
        'cucumber',
        'oil-new',
        'rice-new',
        'sour-cream',
        'cottage-cheese'
    ]
};

export default config;
