import React, {useEffect, useState} from "react";
import {
    Layout,
    Breadcrumb,
    Row,
    Col,
    Input,
    Button,
    Skeleton,
    Form,
    InputNumber,
    Table,
    Switch,
    Collapse,
    message,
    Space,
    Popconfirm,
    Tag,
    Typography,
    Avatar,
    Card,
    List, Alert
} from 'antd';
import {useParams, Link} from 'react-router-dom';
import Api from "../api";
import {
    DeleteOutlined,
    CheckCircleOutlined,
    FileSearchOutlined,
    EditOutlined,
    EllipsisOutlined,
    SettingOutlined,
    UserOutlined
} from "@ant-design/icons";
import moment from "moment";
import dayjs from "dayjs";

const {Content} = Layout;

const ShortUrls = () => {
    const params = useParams();
    const [shortUrl, setShortUrl] = useState(null);
    const [urls, setUrls] = useState([]);
    const [trackingUrl, setTrackingUrl] = useState(null);

    useEffect(() => {
        loadUrls();
    }, [])

    const loadUrls = () => {
        Api.get('/admin/short-url').then(response => {
            console.log(response.data);
            setUrls(response.data)
        })
    }

    const onFinish = (values: any) => {
        const data = {
            url: values.url,
            key: values.key
        }

        Api.post("/admin/short-url", data).then(res => {
            setShortUrl(res.data)
            return message.success("Url successfully shortened!");
        });
        console.log('Success:', values);
    };

    const onFinishTracking = (values: any) => {
        const data = {
            url: values.url,
            key: values.key
        }

        let generatedUrl = values.url;

        if(values.utm_source) {
            generatedUrl += '?utm_source=' + encodeURIComponent(values.utm_source)
        }

        if(values.utm_medium) {
            generatedUrl += '&utm_medium=' + encodeURIComponent(values.utm_medium)
        }
        if(values.utm_content) {
            generatedUrl += '&utm_content=' + encodeURIComponent(values.utm_content)
        }
        if(values.utm_term) {
            generatedUrl += '&utm_term=' + encodeURIComponent(values.utm_term)
        }
        if(values.utm_campaign) {
            generatedUrl += '&utm_campaign=' + encodeURIComponent(values.utm_campaign)
        }

        setTrackingUrl(generatedUrl)
        return message.success("Tracking URL generated")
    };


    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (

        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Tasks</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">

                <h2>Tracking URL koostamine</h2>

                <Form
                    name="tracking"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinishTracking}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Vormi URL *"
                        name="url"
                        rules={[{ required: true, message: 'Please input main URL!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Kampaania nimi *"
                        name="utm_campaign"
                        rules={[{ required: true, message: 'Please input utm_campaign!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Meediakanal *"
                        name="utm_source"
                        rules={[{ required: true, message: 'Please input utm_source!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Meediatüüp *"
                        name="utm_medium"
                        rules={[{ required: true, message: 'Please input utm_medium!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Märksõna "
                        name="utm_term"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Sisu "
                        name="utm_content"
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Generate
                        </Button>
                    </Form.Item>

                    <p>
                        <h3>Generated link:</h3>
                        { trackingUrl }
                    </p>
                </Form>

                <h1>Add short URL</h1>

                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true, url: trackingUrl }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Url"
                        name="url"
                        value={trackingUrl}
                        rules={[{ required: true, message: 'Please input main URL!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Key"
                        name="key"
                        rules={[{ required: true, message: 'Please input the shortened key!' }]}
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Shorten
                        </Button>
                    </Form.Item>
                </Form>

                { shortUrl }

                <List
                    itemLayout="horizontal"
                    dataSource={urls}
                    renderItem={url => {
                        return (
                            <List.Item>
                                <List.Item.Meta
                                    style={{alignItems: "center"}}
                                    title={<b>{url.default_short_url}</b>}
                                    description={<div>
                                        Original: {url.destination_url}
                                        <br/>
                                        visits: {url.visits_count}
                                    </div>
                                    }
                                />
                            </List.Item>
                        )
                    }}
                />
            </div>
        </Content>
    );
};


export default ShortUrls;