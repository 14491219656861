import React from 'react';
import {Button, Checkbox, Divider, Form, Input, message, Row, Space} from 'antd';
import Api from "../api";
import {setCookie} from "../helpers";

const Login = ({user, setUser, setHasAccess}) => {
    const onFinish = (values) => {
        Api.authEmail(values.email, values.password).then((res) => {
            Api.auth(res.data.access_token).then(result => {
                if (process.env.REACT_APP_WHITELIST.includes(result.data.data.email)) {
                    if (values.remember) {
                        setCookie('fl_admin_access_token', res.data.access_token, 1);
                        setCookie('fl_admin_refresh_token', res.data.refresh_token, 1);
                    } else {
                        setCookie('fl_admin_access_token', res.data.access_token );
                        setCookie('fl_admin_refresh_token', res.data.refresh_token);
                    }

                    window.location.reload();
                } else {
                    message.error('Not authorized account');
                }
            })
        }).catch(err => {
            message.error('Invalid username or password');
        })
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Row type="flex" justify="center" align="middle" style={{height: "100vh", overflow: "hidden"}}>
            <Space direction="vertical">
                <h1 style={{textAlign: "center", color: "white"}}>Login</h1>

                <Form
                    name="basic"
                    labelCol={{span: 24}}
                    wrapperCol={{span: 24}}
                    initialValues={{remember: false}}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size="large"
                >
                    <Form.Item
                        label="E-mail"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input size="large"/>
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password size="large"/>
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block size="large">
                            Login
                        </Button>
                        <Divider orientation="center">
                            Or
                        </Divider>
                        <a href="https://app.fitlap.ee">
                            <Button type="default" block size="large">
                                Fitlap app
                            </Button>
                        </a>
                    </Form.Item>
                </Form>

            </Space>
        </Row>
    )
};

export default Login;