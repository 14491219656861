import React, {useEffect, useState} from "react";
import {
    Alert,
    Breadcrumb,
    Button, Checkbox,
    Form,
    Input,
    Layout,
    message,
    Popconfirm,
    Select,
    Skeleton,
    Space,
    Table,
    Tabs,
    Tag
} from 'antd';
import {Link, useParams} from "react-router-dom"
import Api from "../api";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {redirect} from "../helpers";
import TextArea from "antd/es/input/TextArea";
import ReactQuill from "react-quill";


const {Content} = Layout;

const NotificationView = ({onClickEvent}) => {
    const params = useParams();
    const [notification, setNotification] = useState([]);
    const [value, setValue] = useState('');

    useEffect(() => {
        params.id ? loadNotification() : setNotification({'id':'add-new'});
    }, [])


    const loadNotification = () => {
        Api.get('/admin/notifications/' + params.id).then(response => {
            setNotification(response.data.data);
        })
    }


    const onFinish = (values: any) => {
        if(params.id) {
            Api.put("/admin/notifications/" + notification.id, values).then(response => {
                setNotification(response.data.data)
                return message.success('Template updated');
            }).catch(e => {
                return message.error('Template update failed');
            });
        } else {
            let data = {
                'type': values.type ?? 'custom',
                'name': values.name ?? '',
                'title': values.title ?? '',
                'content': values.content ?? '',
                'link': values.link ?? '',
                'status': values.status ?? false,
            };
            Api.post("/admin/notifications", data).then(response => {
                setNotification(response.data.data)
                return message.success('Notification created');
            }).catch(e => {
                return message.error('Notification creation failed');
            });
        }

    }

    const modules = {
            toolbar: [
                [{'header': [1, 2, false]}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link'],
                ['clean'],
                ['emoji']
            ],
            "emoji-toolbar": true,
        },

        formats = [
            'header',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link',
            'emoji'
        ]
    const types = [
        {'value': 'custom', 'label': 'Custom'},
        {'value': 'scheduled', 'label': 'Scheduled'},
        {'value': 'onesignal', 'label': 'Onesignal'}
    ];

    const deleteNotification = (notification) => {
        Api.delete('/admin/notifications/' + notification.id).then(response => {
            setNotification([]);
            setNotification({'id':'deleted'});
            return message.success('Notification deleted');
        }).catch(e => {
            return message.error('Notification deletion failed');
        });
    }

    return (

        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/notifications">Notifications</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{notification.title}</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                {!notification || !notification.id ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Form
                        name="notification"
                        labelCol={{span: 5}}
                        initialValues={{
                            type: notification?.type,
                            image: notification?.image,
                            title: notification?.title,
                            content: notification?.content,
                            link: notification?.link,
                            status: notification?.status,
                            name: notification?.name
                        }}
                        autoComplete="off"
                        onFinish={onFinish}
                    >

                        <Alert style={{margin: '10px 0px'}}
                               message="NB! OneSignal teavitus saab muuta ja kustutada ainult OneSignal kodulehelt."
                               type="warning"
                               showIcon={true}
                               closable
                        />
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: !params.id, message: 'Please insert notification title!' }]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item label="Content" name="content"
                                   rules={[{ required: !params.id, message: 'Please insert notification content!' }]}>
                            <ReactQuill modules={modules} formats={formats} onChange={setValue}/>
                        </Form.Item>

                        <Form.Item
                            label="Link"
                            name="link">
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Type"
                            name="type"
                            rules={[{ required: !params.id, message: 'Please select notification type!' }]}>
                            <Select options={types}></Select>
                        </Form.Item>

                        <Form.Item
                            label="Name"
                            name="name">
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Enabled"
                            name="status"
                            valuePropName="checked"
                        >
                            <Checkbox value="1"></Checkbox>
                        </Form.Item>

                        <Form.Item wrapperCol={{offset: 5, span: 16}}>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>


                            {params.id && (
                                <Popconfirm
                                    title={`Delete notification ${notification.title}?`}
                                    onConfirm={() => deleteNotification(notification)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button type="danger" style={{marginLeft: "1em"}}>
                                        Delete
                                    </Button>
                                </Popconfirm>
                            )}
                        </Form.Item>
                    </Form>
                )}
            </div>
        </Content>
    );
}

export default NotificationView;