import React, {useEffect, useState} from "react";
import {Breadcrumb, Input, Layout, message, Popconfirm, Space, Table, Tabs, Tag} from 'antd';
import {Link} from "react-router-dom"
import Api from "../api";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined} from '@ant-design/icons';
import NewBarcodeIngredient from "../components/new-barcode-ingredient";

const {TabPane} = Tabs;
const {Search} = Input;
const {Content} = Layout;

const Selver = () => {
    const [ingredients, setIngredients] = useState([]);
    const [active, setActive] = useState('fitlap');
    const [meta, setMeta] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [search, setSearch] = useState("");

    useEffect(() => {
        loadIngredients();
    }, [active])

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 30,
            render: (verified, record) => <Space><span>{record.id}</span><span><CheckCircleOutlined
                style={record.verified === 1 ? {color: 'green'} : {color: "red"}}/></span></Space>,
        },
        {
            title: 'SKU',
            dataIndex: 'sku_code',
            key: 'sku_code',
            width: 30,
        },
        {
            title: 'Name',
            dataIndex: 'original_name',
            key: 'original_name',
            render: (name, record) => {
                return (
                    <Link to={"/ingredient/" + record.id}>{name}</Link>
                )
            },
            width: 200,
        },
        {
            title: 'Energy (kcal)',
            dataIndex: 'energy',
            key: 'energy',
            width: 30,
        },

        {
            title: 'Carbohydrate (g)',
            dataIndex: 'carbohydrate',
            key: 'carbohydrate',
            width: 30,
        },
        {
            title: 'Protein (g)',
            dataIndex: 'protein',
            key: 'protein',
            width: 30,
        },
        {
            title: 'Fat (g)',
            dataIndex: 'fat',
            key: 'fat',
            width: 30,
        },
        {
            title: 'Fiber (g)',
            dataIndex: ['nutridata', 'fiber'],
            key: 'fiber',
            width: 30,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: text => <span><Tag color={text === 1 ? "green" : "red"}>{text === 1 ? "active" : "inactive"}</Tag></span>,
        }
    ];

    const loadIngredients = (nextPage, search) => {
        let params = `&per_page=${pageSize}`

        if (search) {
            params += `&keywords=${search}`;
        }
        if (nextPage) {
            params += `&page=${nextPage}`;
        } else {
            params += '&page=1';
        }

        params += '&order_by=id';

        Api.get('/admin/ingredients/selver-list?type=' + active + params).then(response => {
            setPage(response.data.current_page);
            setMeta(response.data);
            setIngredients(response.data.data);
        })
    };

    const renderTabContent = (type) => {
        return (
            <Table pagination={{pageSize: pageSize, total: meta.total}} onChange={onChange} columns={columns}
                   dataSource={ingredients}/>
        )
    }

    const onSearch = (value) => {
        setSearch(value);
        loadIngredients(null, value);
    }

    const onChange = (data) => {
        setPageSize(data.pageSize);
        loadIngredients(data.current);
    };

    const changeTab = (value) => {
        setActive(value);
    }


    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Selver</Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <h1>Ingredients ({meta.total}) <Search placeholder="search" onSearch={onSearch}
                                                       style={{width: 200, float: "right", marginRight: 10}}/></h1>

                <Space>
                    <NewBarcodeIngredient/>
                </Space>


                <Tabs defaultActiveKey="1" onChange={changeTab}>
                    <TabPane tab="Fitlap (Missing SKU code)" key="fitlap">
                        {renderTabContent('fitlap')}
                    </TabPane>
                </Tabs>
            </div>
        </Content>
    );
}

export default Selver;