import {Button, Modal} from "antd";
import moment from "moment";
import React from "react";

const InvoiceModal = ({selectedInvoice, handleModalClose, isModalVisible}) => {
    return (
        <Modal
            title="Invoice Details"
            open={isModalVisible}
            onCancel={handleModalClose}
            footer={[
                <Button key="close" onClick={handleModalClose}>
                    Close
                </Button>,
            ]}
        >
            {selectedInvoice && (
                <div>
                    <p><strong>Invoice ID:</strong> {selectedInvoice.invoice_id}</p>
                    <p><strong>Invoice Key:</strong> {selectedInvoice.invoice_key}</p>
                    <p><strong>Public ID:</strong> {selectedInvoice.public_id}</p>
                    <p><strong>User ID:</strong> {selectedInvoice.user_id}</p>
                    <p><strong>Status:</strong> {selectedInvoice.status}</p>
                    <p><strong>Currency:</strong> {selectedInvoice.currency}</p>
                    <p><strong>Base Currency Multiplier:</strong> {selectedInvoice.base_currency_multi}</p>
                    <p><strong>First Period:</strong> {selectedInvoice.first_period}</p>
                    <p><strong>First Subtotal:</strong> {selectedInvoice.first_subtotal}</p>
                    <p><strong>First Discount:</strong> {selectedInvoice.first_discount}</p>
                    <p><strong>First Shipping:</strong> {selectedInvoice.first_shipping}</p>
                    <p><strong>First Tax:</strong> {selectedInvoice.first_tax}</p>
                    <p><strong>First Total:</strong> {selectedInvoice.first_total}</p>
                    <p><strong>Second Period:</strong> {selectedInvoice.second_period || '-'}</p>
                    <p><strong>Second Subtotal:</strong> {selectedInvoice.second_subtotal || '-'}</p>
                    <p><strong>Second Discount:</strong> {selectedInvoice.second_discount || '-'}</p>
                    <p><strong>Second Shipping:</strong> {selectedInvoice.second_shipping || '-'}</p>
                    <p><strong>Second Tax:</strong> {selectedInvoice.second_tax || '-'}</p>
                    <p><strong>Second Total:</strong> {selectedInvoice.second_total || '-'}</p>
                    <p><strong>Tax Rate:</strong> {selectedInvoice.tax_rate}</p>
                    <p><strong>Tax Title:</strong> {selectedInvoice.tax_title || '-'}</p>
                    <p><strong>Tax Type:</strong> {selectedInvoice.tax_type || '-'}</p>
                    <p><strong>Payment System ID:</strong> {selectedInvoice.paysys_id}</p>
                    <p><strong>Comment:</strong> {selectedInvoice.comment}</p>
                    <p><strong>Affiliate ID:</strong> {selectedInvoice.aff_id || '-'}</p>
                    <p><strong>Coupon Code:</strong> {selectedInvoice.coupon_code || '-'}</p>
                    <p><strong>Coupon ID:</strong> {selectedInvoice.coupon_id || '-'}</p>
                    <p><strong>Due Date:</strong> {selectedInvoice.due_date || '-'}</p>
                    <p><strong>Rebill Date:</strong> {selectedInvoice.rebill_date || '-'}</p>
                    <p><strong>Rebill Times:</strong> {selectedInvoice.rebill_times}</p>
                    <p><strong>Remote Address:</strong> {selectedInvoice.remote_addr}</p>
                    <p><strong>Saved Form ID:</strong> {selectedInvoice.saved_form_id || '-'}</p>
                    <p><strong>Terms:</strong> {selectedInvoice.terms || '-'}</p>
                    <p><strong>Date Added:</strong> {moment(selectedInvoice.tm_added).format('YYYY-MM-DD HH:mm:ss')}
                    </p>
                    <p><strong>Date
                        Started:</strong> {moment(selectedInvoice.tm_started).format('YYYY-MM-DD HH:mm:ss')}</p>
                    <p><strong>Date
                        Cancelled:</strong> {selectedInvoice.tm_cancelled ? moment(selectedInvoice.tm_cancelled).format('YYYY-MM-DD HH:mm:ss') : '-'}
                    </p>
                </div>
            )}
        </Modal>
    )
}

export default InvoiceModal;