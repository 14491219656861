import React, {useEffect, useState} from "react";
import {
    Alert,
    Breadcrumb,
    Button, Checkbox,
    Form,
    Input,
    Layout,
    message,
    Popconfirm,
    Select,
    Skeleton,
    Space,
    Table,
    Tabs,
    Tag
} from 'antd';
import {Link, useParams} from "react-router-dom"
import Api from "../api";
import {CheckCircleOutlined, CheckOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {redirect} from "../helpers";
import ReactQuill from "react-quill";


const {Content} = Layout;

const TrainingVideoView = ({onClickEvent}) => {
    const params = useParams();
    const [video, setVideo] = useState([]);
    const [value, setValue] = useState('');

    useEffect(() => {
        loadVideo()
    }, [])


    const loadVideo = () => {
        Api.get('/admin/training-videos/videos/' + params.id).then(response => {
            setVideo(response.data.data);
        })
    }


    const onFinish = (values: any) => {

    }


    return (

        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/training-videos">Training videos</Link></Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                {!video || !video.id ? (
                    <Skeleton title={true} loading={true} active/>
                ) : (
                    <Form
                        name="training-video"
                        labelCol={{span: 5}}
                        initialValues={{
                            title: video?.title
                        }}
                        autoComplete="off"
                        onFinish={onFinish}
                    >

                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{required: !params.id, message: 'Please insert notification title!'}]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item>
                            <h2>Video preview</h2>
                            <iframe width="560" height="315" src={video.url} title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen></iframe>
                        </Form.Item>


                        {/*<Form.Item wrapperCol={{offset: 5, span: 16}}>*/}
                        {/*    <Button type="primary" htmlType="submit">*/}
                        {/*        Save*/}
                        {/*    </Button>*/}


                        {/*    {params.id && (*/}
                        {/*        <Popconfirm*/}
                        {/*            title={`Delete notification ${notification.title}?`}*/}
                        {/*            onConfirm={() => deleteNotification(notification)}*/}
                        {/*            okText="Yes"*/}
                        {/*            cancelText="No"*/}
                        {/*        >*/}
                        {/*            <Button type="danger" style={{marginLeft: "1em"}}>*/}
                        {/*                Delete*/}
                        {/*            </Button>*/}
                        {/*        </Popconfirm>*/}
                        {/*    )}*/}
                        {/*</Form.Item>*/}
                    </Form>
                )}
            </div>
        </Content>
    );
}

export default TrainingVideoView;