import React, {useEffect} from "react";
import {Layout, Breadcrumb, Row, Col, Statistic, Table, Button, Space, message} from 'antd';
import {ClearOutlined} from '@ant-design/icons';
import {getCookie, config} from "../helpers";
import Api from "../api";
import DailyPayments from "../components/charts/daily-payments";
import DailyVisitors from "../components/charts/daily-visitors";
import DailyRegistrations from "../components/charts/daily-registrations";
import LatestEvents from "../components/charts/latest-events";
import LatestInvoices from "../components/charts/latest-invoices";
import ErrorLogChart from "../components/charts/error-log-chart";

const {Content} = Layout;

const Dashboard = ({user}) => {
    const clearCache = () => {
        Api.get('/admin/clear_cache').then(res => {
            message.success(`Cache cleared!`);
        }).catch(e => {
            return message.error(`${e.message}`);
        })
    }

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item>Fitlap</Breadcrumb.Item>
                <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            </Breadcrumb>

            <h1>Dashboard</h1>

            <Row gutter={20}>
                <Col span={24} md={12}>
                    <div className="site-layout-card">
                        <DailyVisitors/>
                    </div>
                </Col>

                <Col span={24} md={12}>
                    <div className="site-layout-card">
                        <DailyPayments/>
                    </div>
                </Col>

                <Col span={24} md={12}>
                    <div className="site-layout-card">
                        <DailyRegistrations/>
                    </div>

                    <div className="site-layout-card">
                        <ErrorLogChart/>
                    </div>
                </Col>

                <Col span={24} md={12}>
                    <div className="site-layout-card">
                        <LatestInvoices/>
                    </div>
                </Col>


                <Col span={24} md={12}>
                    <div className="site-layout-card">
                        <Space direction={"vertical"}>
                            <h1>{getCookie('api_url') ? getCookie('api_url') : config('api.url')}</h1>
                            <Button onClick={clearCache}><ClearOutlined/>Clear cache</Button>
                        </Space>
                    </div>
                </Col>


            </Row>
        </Content>
    );
}

export default Dashboard;